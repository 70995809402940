import React from 'react';
import { Avatar, Box, Grid, Paper, Tooltip, Typography, Button, useTheme } from '@material-ui/core';

import { FiBriefcase, FiMap } from 'react-icons/fi';
import { HiDotsVertical } from 'react-icons/hi';
import { AiOutlinePlus } from 'react-icons/ai';

import { AppIconButton, DocumentsDialog } from '@vyce/core/src/components';
import { monochrome } from '@vyce/core/src/theme/styles';
import { getAvatar } from '@vyce/core/src/utils/getAvatar';
import { useBooleanState } from '@vyce/core/src/hooks';

import useStyles from '../styles';
import { ExpandableViews } from '../components/ExpandableViews';
import { ExpandableViewItem, QualificationType, UserPreviewProps, WorkExperienceDTO } from '../types';
import { useUserPreviewData } from '../hooks';
import { WorkExperienceItem } from './components/WorkExperienceItem';
import { QualificationItem } from './components/QualificationItem';
import { Skills } from './components/Skills';
import { Bio } from './components/Bio';
import { RightToWork } from './components/RightToWork';
import { PersonalDetails } from './components/PersonalDetails';
import { LimitedCompany } from './components/LimitedCompany';
import { PaymentsAndAgreements } from './components/PaymentsAndAgreements';
import { ContactDetails } from './components/ContactDetails';
import { EmergencyContact } from './components/EmergencyContact';
import { BankDetails } from './components/BankDetails';
import { HealthRecords } from './components/HealthRecords';
import { AdditionalInformation } from './components/AdditionalInformation';

export const UserPreview = ({
  goToEditView,
  rtw,
  passport,
  hasPermissionForCreateQualification,
  withAddQualificationModal = false,
  isLegend,
  renderQualificationModal,
  ...restProps
}: UserPreviewProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const [isOpen, setDialogOpen, setDialogClose] = useBooleanState(false);

  const {
    user,
    skills,
    role,
    isMobile,
    isDocumentsInfoDialogOpen,
    formattedQualifications,
    preparedPersonalDetails,
    preparedContactDetails,
    preparedRightToWorkDetails,
    preparedBankDetails,
    preparedEmergencyContactDetails,
    preparedLimitedCompanyDetails,
    preparedPaymentsDetails,
    preparedSurveysDetails,
    preparedAdditionalInfo,
    setDocumentsInfoDialogOpen,
    setDocumentsInfoDialogClose,
  } = useUserPreviewData({ ...restProps, passport });

  const getWorkExperienceItem = (item: ExpandableViewItem) => {
    return <WorkExperienceItem {...(item as WorkExperienceDTO)} />;
  };

  const getQualificationItem = (item: QualificationType) => {
    return <QualificationItem qualification={item} />;
  };

  return (
    <>
      <Paper className={classes.paper} variant="outlined">
        {goToEditView && (
          <Tooltip title="Edit" placement="left">
            <AppIconButton className={classes.editIcon} onClick={() => goToEditView()}>
              <HiDotsVertical />
            </AppIconButton>
          </Tooltip>
        )}

        <div className={classes.poster} />

        <Avatar src={user.photo?.url || getAvatar(user?.gender)} className={classes.avatar} />

        <Box className={classes.infoContainer}>
          <Typography variant="h5" className={classes.name} align="center">
            {`${user.first_name} ${user.last_name}`}
          </Typography>

          {role && (
            <Box display="flex" alignItems="center" justifyContent="center">
              {!isMobile && (
                <FiBriefcase
                  style={{ marginRight: theme.spacing(0.5) }}
                  size="16px"
                  color={theme.palette.primary.main}
                />
              )}
              <Typography color="primary" align="center" variant="subtitle1">
                {role}
              </Typography>
            </Box>
          )}

          {user.address && (
            <Box display="flex" alignItems="center" justifyContent="center">
              {!isMobile && (
                <FiMap
                  style={{ marginRight: theme.spacing(0.5) }}
                  size="16px"
                  color={monochrome.mediumdark}
                />
              )}
              <Typography color="textSecondary" align="center" variant="subtitle1">
                {user.address.address_line_3}, {user.address.country}
              </Typography>
            </Box>
          )}

          <Box className={classes.line} marginTop={isMobile ? 2 : 4} marginBottom={isMobile ? 2 : 5} />

          <Box display="flex" flexDirection="column" gridGap={16} width="100%" maxWidth={1240}>
            {user.worker?.biography && <Bio bio={user.worker.biography} withTitle />}
            <Grid container spacing={5}>
              {!!user.worker?.positions?.length && (
                <Grid item xs={12} lg={6}>
                  <ExpandableViews
                    getDisplayedItem={getWorkExperienceItem}
                    title="Work Experience"
                    items={user.worker.positions as WorkExperienceDTO[]}
                  />
                </Grid>
              )}
              {(!!formattedQualifications.length || hasPermissionForCreateQualification) && (
                <Grid item xs={12} lg={6}>
                  <ExpandableViews
                    getDisplayedItem={getQualificationItem}
                    title="Qualifications"
                    items={formattedQualifications}>
                    {hasPermissionForCreateQualification ? (
                      <Box display="flex" justifyContent="flex-end" marginTop={2}>
                        <Button
                          onClick={setDialogOpen}
                          fullWidth={isMobile}
                          startIcon={<AiOutlinePlus />}
                          size="small"
                          color="primary"
                          variant="contained">
                          Add Qualifications
                        </Button>
                      </Box>
                    ) : null}
                  </ExpandableViews>
                </Grid>
              )}

              {!!skills?.length && (
                <Grid item xs={12} lg={6}>
                  <Skills skills={skills} />
                </Grid>
              )}

              {preparedRightToWorkDetails.isShow && (
                <Grid item xs={12} lg={6}>
                  <RightToWork
                    {...preparedRightToWorkDetails}
                    onOpenIdInfoDialog={setDocumentsInfoDialogOpen}
                    showViewDocumentButton={rtw || passport}
                  />
                </Grid>
              )}

              {preparedPersonalDetails.isShow && (
                <Grid item xs={12} lg={6}>
                  <PersonalDetails {...preparedPersonalDetails} isShowAgencyInfo={isLegend}>
                    {isLegend && user.employee?.uuid && (
                      <AdditionalInformation
                        {...preparedAdditionalInfo}
                        employeeId={user.employee?.uuid ?? ''}
                      />
                    )}
                  </PersonalDetails>
                </Grid>
              )}

              {preparedLimitedCompanyDetails.isShow && (
                <Grid item xs={12} lg={6}>
                  <LimitedCompany {...preparedLimitedCompanyDetails} />
                </Grid>
              )}

              {preparedContactDetails.isShow && (
                <Grid item xs={12} lg={6}>
                  <ContactDetails {...preparedContactDetails} />
                </Grid>
              )}

              {preparedEmergencyContactDetails.isShow && (
                <Grid item xs={12} lg={6}>
                  <EmergencyContact {...preparedEmergencyContactDetails} />
                </Grid>
              )}

              {preparedBankDetails.isShow && (
                <Grid item xs={12} lg={6}>
                  <BankDetails {...preparedBankDetails} />
                </Grid>
              )}

              {preparedPaymentsDetails.isShow && (
                <Grid item xs={12} lg={6}>
                  <PaymentsAndAgreements {...preparedPaymentsDetails} />
                </Grid>
              )}

              <Grid item xs={12} lg={6}>
                <HealthRecords {...preparedSurveysDetails} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>

      <DocumentsDialog
        open={isDocumentsInfoDialogOpen}
        onClose={setDocumentsInfoDialogClose}
        passport={passport}
        idVerification={user.id_verification}
        rtw={rtw}
        rtwVerification={user.rtw_verification}
      />
      {withAddQualificationModal &&
        hasPermissionForCreateQualification &&
        renderQualificationModal &&
        renderQualificationModal({ isOpen, onClose: setDialogClose })}
    </>
  );
};
