import isBefore from 'date-fns/isBefore';

import { getFormattedDate } from '../../utils/dates';
import {
  Candidate,
  Certificate,
  Course,
  IDVerification,
  QualificationsDTO,
  SurveyContent,
  Test,
  User,
} from '../../types';

import { QualificationType, Statuses } from './types';
import { DOCUMENT_STATUSES } from '../../constants';

const PASS = 'PASS';
const UNCONNECTED_MESSAGE = 'Provided';
const customQualifications = ['industry', 'academic'] as const;

const checkExpiredDate = (date: string) => isBefore(new Date(), new Date(date));

export const getWorkerQualifications = (qualifications: QualificationsDTO): QualificationType[] => {
  if (qualifications) {
    const tests = qualifications?.tests?.items?.map((test: Test) => ({
      verified: test.result === PASS,
      type: 'test',
      ...test,
    }));
    const courses = qualifications?.courses?.items?.map((course: Course) => ({
      verified: checkExpiredDate(course.expiry_date),
      type: 'course',
      ...course,
    }));
    const certificates = qualifications?.certificates?.items?.map((certificate: Certificate) => ({
      type: 'certificate',
      verified:
        (certificate.source === 'custom' &&
          customQualifications.includes(certificate.crt_type as (typeof customQualifications)[number])) ||
        true,
      ...certificate,
      expiry_date: certificate.crt_type === 'academic' ? null : certificate.expiry_date,
      reference_number: certificate.additional_data?.reference_number || certificate.serial_number || '',
    }));

    return [...tests, ...certificates, ...courses] as QualificationType[];
  }

  return [];
};

export const getSurveyStatus = (content?: SurveyContent[]) => {
  const answered = content?.filter(item => item?.answer?.yes_no !== null).length || 0;
  const length = content?.length ?? 0;

  return {
    verified: length !== 0 && answered === length,
    answered,
    total: length,
  };
};

export const getPreparedPersonalDetails = ({
  date_of_birth = '',
  gender = '',
  nationality,
  context,
  employee,
  address,
}: Pick<User, 'date_of_birth' | 'gender' | 'nationality' | 'context' | 'employee' | 'address'>) => {
  const nationalityName = nationality?.name ?? '';
  const NINumber = context?.ni_number ?? '';
  const utr = employee?.utr ?? '';
  const addressSnippet = address?.address_snippet ?? '';
  return {
    ni_number: { title: 'NI Number', value: NINumber },
    utr: { title: 'UTR Number', value: utr },
    address: { title: 'Address', value: addressSnippet },
    date_of_birth: { title: 'Date of Birth', value: date_of_birth },
    gender: { title: 'Gender', value: gender || undefined },
    nationality: { title: 'Nationality', value: nationalityName },
    is_agency_worker: { title: 'Employment type', value: employee?.is_agency_worker ? 'Agency worker' : '' },
    agency_ref_number: { title: 'Our reference number', value: employee?.agency_ref_number ?? '' },
    isShow:
      date_of_birth ||
      gender ||
      nationalityName ||
      NINumber ||
      utr ||
      addressSnippet ||
      employee?.is_agency_worker ||
      employee?.agency_ref_number,
  };
};

export const getPreparedAdditionalInfo = ({ employee }: Pick<User, 'employee'>) => {
  return {
    our_reference_number: { title: 'Our reference number', value: employee?.agency_ref_number ?? undefined },
    employment_type: {
      title: 'Employment type',
      value: employee?.is_agency_worker ? 'Agency worker' : undefined,
    },
    isShow: !!(employee?.is_agency_worker || employee?.agency_ref_number),
  };
};

export const getPreparedContactDetails = ({ email = '', phone = '' }: { email: string; phone: string }) => {
  return {
    email: { title: 'Email Address', value: email },
    phone: { title: 'Phone Number', value: phone },
    isShow: email || phone,
  };
};

export const getPreparedRightToWorkDetails = ({ rtw_verification, context, id_verification }: User) => {
  const { status = '', expiration_date } = rtw_verification ?? {};
  const { status: idVerifiedStatus = '', expiration_date: id_expiration_date } = id_verification ?? {};

  return {
    rtw: {
      title: 'Right to Work',
      value: status as keyof typeof Statuses,
      expiration_date,
      verified: rtw_verification?.verified ?? false,
    },
    id: {
      title: 'ID',
      value: idVerifiedStatus,
      expiration_date: id_expiration_date,
      verified: isIDVerified(id_verification?.status),
    },

    share_code: {
      title: 'Share Code',
      value: context?.rtw_share_code ?? '',
      verified: true,
    },
    isShow: context?.rtw_share_code || idVerifiedStatus || rtw_verification?.status,
  };
};

export const isIDVerified = (status?: DOCUMENT_STATUSES): boolean => {
  if (!status) {
    return false;
  }
  return status === DOCUMENT_STATUSES.VERIFIED || status === DOCUMENT_STATUSES.EXPIRING;
};

export const getPreparedBankDetails = ({ employee }: User) => {
  const {
    account_name = '',
    sort_code = '',
    account_number = '',
    building_society_roll_number = '',
  } = employee?.bank_account ?? {};

  return {
    account_name: {
      title: 'Name of Account Holder',
      value: account_name,
    },
    sort_code: {
      title: 'Sort Code',
      value: sort_code,
    },
    account_number: {
      title: 'Account Number',
      value: account_number,
    },
    building_society_roll_number: {
      title: 'Building Society Roll Number',
      value: building_society_roll_number,
    },
    isShow: account_name || sort_code || account_number || building_society_roll_number,
  };
};

export const getPreparedEmergencyContactDetails = ({ employee }: User) => {
  const { emergency_contact_name = '', emergency_contact_number = '' } = employee ?? {};
  return {
    name: { title: 'Emergency Contact', value: emergency_contact_name },
    phone: { title: 'Phone Number', value: emergency_contact_number },
    isShow: emergency_contact_name || emergency_contact_number,
  };
};

export const getPreparedLimitedCompanyDetails = ({ employee }: User) => {
  const {
    company_name = '',
    company_reg_number = '',
    vat_reg_number = '',
    vat_registered = false,
    company_utr = '',
  } = employee ?? {};

  return {
    company_name: { title: 'LTD Company Name', value: company_name },
    company_reg_number: { title: 'LTD Company Number', value: company_reg_number },
    company_utr: { title: 'LTD Company UTR', value: company_utr },
    vat_reg_number: { title: 'VAT Number', value: vat_reg_number, verified: vat_registered },
    isShow: company_name || company_reg_number || vat_reg_number,
  };
};

export const getPreparedPaymentsDetails = ({
  paymentsLink = '',
  timesheetsLink = '',
  agreementsLink = '',
}: {
  paymentsLink?: string;
  timesheetsLink?: string;
  agreementsLink?: string;
}) => {
  return {
    payments: { title: 'Payments', value: paymentsLink },
    timesheets: { title: 'Timesheets', value: timesheetsLink },
    agreements: { title: 'Agreements', value: agreementsLink },
    isShow: paymentsLink || timesheetsLink,
  };
};

export const getPreparedHiringPersonalDetails = ({
  date_of_birth = '',
  nationality,
  connected = false,
}: Pick<
  User & {
    connected: boolean;
  },
  'date_of_birth' | 'nationality' | 'connected'
>) => {
  const years = getFullYears(date_of_birth ?? '');
  return {
    date_of_birth: {
      title: 'Date of Birth',
      value: connected ? getFormattedDate(date_of_birth) : UNCONNECTED_MESSAGE,
      extra: connected ? `${years} years old` : '',
    },
    nationality: { title: 'Nationality', value: nationality?.name ?? '' },
  };
};

export const getPreparedHiringRightToWorkDetails = ({
  id_verified = false,
  rtw_verified = false,
}: Candidate) => {
  return {
    rtw: {
      title: 'Right to Work',
      verified: rtw_verified,
    },
    id: {
      title: 'ID',
      verified: id_verified,
    },
  };
};

export const getFullYears = (date: string) => new Date().getFullYear() - new Date(date ?? '').getFullYear();
