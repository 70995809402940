import { axios } from '@vyce/core/src/api';

import {
  AddEmployeeToPayScheduleRequest,
  Cis300SubmitsRequest,
  CISTimesheetRequest,
  CreateOrUpdateCompanyBankAccountRequest,
  DownloadCISReportRequest,
  DownloadHealthAndSafetyPDFRequest,
  GenerateNextPayrun,
  GetAgreementRequest,
  GetAgreementsRequest,
  GetCompanyBankAccountRequest,
  GetEmployeeTimesheetsRequest,
  GetPayrun,
  GetPayslipsRequest,
  GetTimesheetData,
  GetTimesheets,
  ListEmployeeRequest,
  ListPaySchedulesRequest,
  MarkAsPaidRequest,
  RefreshCISReportRequest,
  RegisterPayCompanyData,
  RemoveEmployeeRequest,
  SendBulkPayStatements,
  SendCISReportRequest,
  SendPayslipsRequest,
  TimesheetByStaffDTO,
} from '../types';
import {
  Employee,
  HMRCSetting,
  PaySchedule,
  PayrollPayload,
  ReportPayload,
  TimesheetLine,
} from '../../types';
import { LookupEmployees } from '../pay';
import { GRID_PAGE_SIZE } from '../../constants';

export const getTimesheetsRequest = (payload: PayrollPayload) => {
  return axios.post(`/admin/pay/companies/timesheets/search`, payload);
};

export const getPayrunsRequest = (payload: PayrollPayload) => {
  return axios.post(`/admin/pay/companies/pay_runs/search`, payload);
};

export const getPayrunRequest = ({ token, companyId, payrunId, data }: GetPayrun) => {
  return axios.post(`/admin/pay/companies/${companyId}/pay_runs/${payrunId}`, data);
};

export const getTimesheetRequest = (companyId: string, payrunId: string, data: GetTimesheetData) => {
  return axios.post<TimesheetByStaffDTO>(`/admin/pay/company/${companyId}/timesheets/${payrunId}`, data);
};

export const importTimesheetRequest = ({
  token,
  companyId,
  payrunId,
  payScheduleId,
  file,
  mapping,
}: CISTimesheetRequest) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);
  if (mapping) {
    bodyFormData.append('mapping', JSON.stringify(mapping));
  }
  return axios.post(
    `/admin/pay/companies/${companyId}/pay_schedules/${payScheduleId}/pay_runs/${payrunId}/timesheets`,
    bodyFormData
  );
};

export const submitTimesheetRequest = (
  companyId: string,
  payScheduleId: string,
  payrunId: string,
  data: GetTimesheetData
) => {
  return axios.post(
    `/admin/pay/companies/${companyId}/pay_schedules/${payScheduleId}/timesheets/${payrunId}/submit`,
    data
  );
};

export const reopenTimesheetRequest = (
  token: string,
  companyId: string,
  payScheduleId: string,
  payrunId: string
) => {
  return axios.post(
    `/admin/pay/company/${companyId}/pay_schedule/${payScheduleId}/timesheets/${payrunId}/reopen`
  );
};

export const deleteTimesheetLineRequest = (token: string, companyId: string, timesheetLineId: string) => {
  return axios.delete(`/admin/pay/company/${companyId}/timesheet_lines/${timesheetLineId}`);
};

export const createTimesheetLineRequest = (
  token: string,
  companyId: string,
  data: TimesheetLine,
  payrunId: string
) => {
  return axios.post(
    `/admin/pay/company/${companyId}/pay_run/${payrunId}/employee/${data.employee_id}/timesheet_lines`,
    data
  );
};

export const editTimesheetLineRequest = (
  token: string,
  companyId: string,
  timesheetLineId: string,
  data: TimesheetLine
) => {
  return axios.patch(`/admin/pay/company/${companyId}/timesheet_lines/${timesheetLineId}`, data);
};

export const lookupEmployeesRequest = ({ token, companyId, payScheduleId, substring }: LookupEmployees) => {
  const query = substring ? `?substring=${substring}&limit=20` : '?limit=20';
  return axios.get(`/admin/pay/company/${companyId}/pay_schedule/${payScheduleId}/employees${query}`);
};

export const markPayrunAsPaidRequest = ({
  token,
  companyId,
  payScheduleId,
  payrunId,
}: MarkAsPaidRequest) => {
  return axios.post(
    `/admin/pay/company/${companyId}/pay_schedules/${payScheduleId}/pay_runs/${payrunId}/mark_as_paid`
  );
};

export const markPayrunAsPaidForEmployeeRequest = ({
  token,
  companyId,
  payScheduleId,
  payrunId,
  employeeId,
}: MarkAsPaidRequest) => {
  return axios.post(
    `/admin/pay/companies/${companyId}/pay_schedules/${payScheduleId}/pay_runs/${payrunId}/employees/${employeeId}/mark_as_paid`
  );
};

export const sendPayslipsByEmailRequest = ({ token, payrunLineId }: SendPayslipsRequest) => {
  return axios.post(`/admin/pay/payslips/${payrunLineId}/send_email`);
};

export const sendPayslipsBySMSRequest = ({ token, payrunLineId, companyId }: SendPayslipsRequest) => {
  return axios.post(`/admin/pay/payslips/${payrunLineId}/send_sms?company_id=${companyId}`);
};

export const getPayslipsPDFRequest = (id: string) => {
  return axios.post(
    `/admin/pay/payslips/${id}`,
    {},
    {
      headers: {
        accept: 'application/json',
      },
      responseType: 'blob',
    }
  );
};

export const getBACSFormatsRequest = () => {
  return axios.get(`/admin/pay/bacs`);
};

export const downloadPayrunBACSRequest = (
  companyId: string,
  payrunId: string,
  data: { bacs_format: string; unpaid: boolean }
) => {
  return axios.post(`/admin/pay/company/${companyId}/pay_runs/${payrunId}/bacs`, data);
};

export const downloadPayrunsBACSRequest = (
  companyId: string,
  data: { bacs_format: string; unpaid: boolean; pay_runs: string[] }
) => {
  return axios.post(`/admin/pay/company/${companyId}/pay_runs/bacs`, data);
};

export const getCisReportsRequest = (payload: ReportPayload) => {
  return axios.post(`/hmrc/reports/cis300/search`, payload);
};

export const getUserCisRequest = (userId: string) => {
  return axios.post(`/hmrc/transactions/cis_tax_rates/users/${userId}`, {
    offset: 0,
    limit: 1,
    order_by: [
      {
        field_name: 'updated_at',
        desc: true,
      },
    ],
  });
};

export const downloadCISReportRequest = ({ reportId }: DownloadCISReportRequest) => {
  return axios.get(`/hmrc/reports/cis300/${reportId}/xlsx`, {
    headers: {
      accept: 'application/json',
    },
    responseType: 'blob',
  });
};

export const refreshCISReportRequest = ({ companyId, taxYear, taxMonth }: RefreshCISReportRequest) => {
  return axios.post(`/hmrc/reports/cis300/refresh`, {
    company_id: companyId,
    tax_year: taxYear,
    tax_month: taxMonth,
  });
};

export const sendCis300ToHMRCRequest = ({ companyId, taxMonth, taxYear }: SendCISReportRequest) => {
  return axios.post(`/hmrc/reports/cis300/send`, {
    company_id: companyId,
    tax_month: taxMonth,
    tax_year: taxYear,
  });
};

export const getPaySchedulesRequest = ({
  companyId,
  substring,
  offset = 0,
  limit = GRID_PAGE_SIZE,
}: ListPaySchedulesRequest) => {
  let query = `?limit=${limit}&offset=${offset}`;
  if (companyId) {
    query += `&company_id=${companyId}`;
  }
  if (substring) {
    query += `&substring=${substring}`;
  }
  return axios.get(`/admin/pay/pay_schedules${query}`);
};

export const getNextPayPeriodRequest = (payScheduleId: string) => {
  return axios.get(`/admin/pay/pay_schedule/${payScheduleId}/next_pay_period`);
};

export const generateNextPayrunRequest = ({ payScheduleId, copyPrevious }: GenerateNextPayrun) => {
  const query = copyPrevious ? '?copy_employees_only=true' : '';
  return axios.post(`/admin/pay/pay_schedule/${payScheduleId}/generate_next_pay_run${query}`);
};

export const getPayScheduleRequest = (companyId: string, payScheduleId: string) => {
  return axios.get(`/admin/pay/company/${companyId}/pay_schedules/${payScheduleId}`);
};

export const getHMRCSettingsByCompanyRequest = (companyId: string) => {
  return axios.get<HMRCSetting>(`/admin/pay/company/${companyId}/hmrc_settings`);
};

export const updatePaySchedulesRequest = (companyId: string, data: PaySchedule) => {
  return axios.patch(`/admin/pay/company/${companyId}/pay_schedules/${data.uuid}`, data);
};

export const getEmployeeListRequest = ({ data }: ListEmployeeRequest) => {
  return axios.post(`/admin/pay/employees`, data);
};

export const lookupEmployeeListRequest = ({ data }: ListEmployeeRequest) => {
  return axios.post(`/admin/pay/employees/lookup`, data);
};

export const updateEmployeeRequest = (data: Employee, employeeId: string) => {
  return axios.patch(`/admin/pay/employees/${employeeId}`, data);
};

export const searchPayslipsRequest = ({ userId, data }: GetPayslipsRequest) => {
  return axios.post(`/admin/pay/users/${userId}/payslips/search`, data);
};

export const getPayrunAsFileRequest = (token: string, companyId: string, payrunId: string) => {
  return axios.post(
    `/admin/pay/companies/${companyId}/pay_runs/${payrunId}/export_to_xslx`,
    {},
    {
      headers: {
        accept: 'application/json',
      },
      responseType: 'blob',
    }
  );
};

export const getTimesheetAsFileRequest = (token: string, companyId: string, payrunId: string) => {
  return axios.post(
    `/admin/pay/companies/${companyId}/timesheets/${payrunId}/export_to_xslx`,
    {},
    {
      headers: {
        accept: 'application/json',
      },
      responseType: 'blob',
    }
  );
};

export const getEmployeeTimesheetsRequest = ({ userId, data }: GetEmployeeTimesheetsRequest) => {
  return axios.post(`/admin/pay/users/${userId}/timesheet_lines`, data);
};

export const checkEmployeeCISRequest = (employee_id: string) => {
  return axios.post(`/admin/pay/employees/${employee_id}/check_cis`);
};

export const checkEmployeeVATRequest = (employee_id: string, vat_number: string) => {
  return axios.post(`/admin/pay/employees/${employee_id}/check_vat`, { vat_number });
};

export const refreshPayrunRequest = (
  token: string,
  companyId: string,
  payScheduleId: string,
  payrunId: string
) => {
  return axios.post(
    `/admin/pay/company/${companyId}/pay_schedules/${payScheduleId}/pay_runs/${payrunId}/refresh`
  );
};

export const deleteTimesheetRequest = (companyId: string, payrunId: string) => {
  return axios.delete(`/admin/pay/companies/${companyId}/timesheets/${payrunId}`);
};

export const createPayScheduleRequest = (
  token: string,
  companyId: string,
  data: any,
  outsourced: boolean
) => {
  return axios.post(`/admin/pay/company/${companyId}/pay_schedules?outsourced=${outsourced}`, data);
};

export const companyRegistrationRequest = (
  token: string,
  companyId: string,
  data: RegisterPayCompanyData
) => {
  return axios.post(`/admin/pay/companies/${companyId}`, data);
};

export const getTimesheetMappingRequest = (token: string, companyId: string, file: File) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);
  return axios.post(`/admin/pay/timesheet_mappings`, bodyFormData);
};

export const removeEmployeeFromPayScheduleRequest = ({
  token,
  companyId,
  payScheduleId,
  employeeId,
}: RemoveEmployeeRequest) => {
  let query = `?company_id=${companyId}`;
  if (payScheduleId) {
    query += `&pay_schedule_id=${payScheduleId}`;
  }
  return axios.delete(`/admin/pay/employees/${employeeId}/pay_schedules${query}`);
};

export const getEmployeePaySchedulesRequest = (employeeId: string) => {
  return axios.post(`/admin/pay/employees/${employeeId}/pay_schedules/search`, {
    offset: 0,
    limit: 100,
  });
};

export const changeEmployeePaySchemeRequest = (
  payScheduleId: string,
  employeeId: string,
  payScheme: string
) => {
  return axios.post(`/admin/pay/pay_schedules/${payScheduleId}/employees/${employeeId}/pay_scheme`, {
    pay_scheme: payScheme,
  });
};

export const sendPayslipsToEmployeesBySMSRequest = ({ token, payrunId }: SendBulkPayStatements) => {
  return axios.post(`/admin/pay/pay_runs/${payrunId}/payslips/send_by_sms`);
};

export const sendPayslipsToEmployeesByEmailRequest = ({ token, payrunId }: SendBulkPayStatements) => {
  return axios.post(`/admin/pay/pay_runs/${payrunId}/payslips/send_by_email`);
};

export const downloadHealthAndSafetyPDFRequest = ({ userId }: DownloadHealthAndSafetyPDFRequest) => {
  return axios.post(
    `/admin/pay/agreements/users/${userId}/health_and_safety`,
    {},
    {
      responseType: 'blob',
    }
  );
};

export const getAgreementsRequest = ({
  token,
  substring,
  pay_scheme,
  limit = 1000,
  offset = 0,
  order_by,
  userId,
}: GetAgreementsRequest) => {
  return axios.post(`/admin/pay/agreements/users/${userId}`, {
    substring,
    pay_scheme,
    limit,
    offset,
    order_by,
  });
};

export const downloadAgreementRequest = ({ token, payScheduleId, userId }: GetAgreementRequest) => {
  return axios.post(
    `/admin/pay/agreements/users/${userId}/pay_schedules/${payScheduleId}`,
    {},
    {
      responseType: 'blob',
    }
  );
};

export const getCompanyBankAccountRequest = ({ companyId }: GetCompanyBankAccountRequest) => {
  return axios.get(`/admin/pay/companies/${companyId}/bank_account`);
};

export const createOrUpdateCompanyBankAccountRequest = ({
  companyId,
  bankAccount,
}: CreateOrUpdateCompanyBankAccountRequest) => {
  return axios.put(`/admin/pay/companies/${companyId}/bank_account`, bankAccount);
};

export const downloadPayslipsRequestByLegend = ({ userId, data }: GetPayslipsRequest) => {
  return axios.post(`/admin/pay/users/${userId}/payslips/xlsx`, data, {
    responseType: 'blob',
  });
};

export const addEmployeeToPaySchedulesRequestByLegend = ({
  companyId,
  payScheduleId,
  employeeId,
  payScheme,
  notifyEmployee,
  notifyEmployer,
}: AddEmployeeToPayScheduleRequest) => {
  return axios.post(
    `/admin/pay/companies/${companyId}/pay_schedules/${payScheduleId}/employees/${employeeId}`,
    { pay_scheme: payScheme, notify_employee: notifyEmployee, notify_employer: notifyEmployer }
  );
};

export const getCis300SubmitsRequest = (payload: Cis300SubmitsRequest) => {
  return axios.post(`/hmrc/reports/cis300/submits/search`, payload);
};
