import React, { useContext, useEffect, useState } from 'react';

import { Box, Button, Typography, Grid, FormControlLabel } from '@material-ui/core';
import { BiPencil } from 'react-icons/bi';
import { HiPlus } from 'react-icons/hi';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { DetailItem, Employee } from '@vyce/core/src/types';
import { AppTextFieldWithLimit } from '@vyce/core/src/components/controlled-inputs/AppTextFieldWithLimit';
import { AppCheckbox } from '@vyce/core/src/components/inputs';
import { updateEmployeeRequest } from '@vyce/core/src/api/legend/pay';
import { useBooleanState } from '@vyce/core/src/hooks';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';
import { LegendUserPreviewContext } from '@vyce/core/src/contexts/legendUserPreviewProvider';
import { ButtonTitleWithLoading } from '@vyce/core/src/components';

import useStyles from '../../styles';
import { BoxWrapper } from './BoxWrapper';

interface AdditionalInfoProps {
  our_reference_number: DetailItem;
  employment_type: DetailItem;
  employeeId: string;
  isShow: boolean;
}

interface ButtonProps {
  icon: React.ReactNode;
  text: string;
  type: string;
}

interface FormData {
  our_reference_number: string;
  employment_type: boolean;
}

const options = {
  edit: {
    icon: <BiPencil size="16px" />,
    text: 'Edit',
    type: 'edit',
  },

  add: {
    icon: <HiPlus size="16px" />,
    text: 'Add',
    type: 'add',
  },
  save: {
    icon: null,
    text: 'Save',
    type: 'save',
  },
};

const DataRowItem = ({ title, value }: { title?: string; value?: string }) => {
  const classes = useStyles();

  if (!value) return null;
  return (
    <Box className={classes.recordWrapper}>
      <Grid item xs={12} sm={6} lg={5}>
        <Typography className={classes.bold}>{title}:</Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={7}>
        <Box display="flex" gridGap={16}>
          <Typography color="textSecondary" className={classes.bigTextWrap}>
            {value}
          </Typography>
        </Box>
      </Grid>
    </Box>
  );
};

export const AdditionalInformation = ({
  our_reference_number,
  employment_type,
  employeeId,
  isShow,
}: AdditionalInfoProps) => {
  const [selectedMode, setSelectedMode] = useState<ButtonProps | null>(null);
  const [isLoading, setIsLoadingTrue, setIsLoadingFalse] = useBooleanState(false);

  const { handleServerError, showNotification } = useContext(NotificationContext);
  const { fetchUserPreviewData } = useContext(LegendUserPreviewContext);

  const methods = useForm<FormData>({
    defaultValues: {
      our_reference_number: our_reference_number.value ?? '',
      employment_type: !!employment_type.value,
    },
  });

  const updateEmployee = async (data: FormData) => {
    setIsLoadingTrue();
    try {
      await updateEmployeeRequest(
        { is_agency_worker: data.employment_type, agency_ref_number: data.our_reference_number } as Employee,
        employeeId
      );
      await fetchUserPreviewData?.();
      data.employment_type || data.our_reference_number
        ? setSelectedMode(options.edit)
        : setSelectedMode(options.add);
      showNotification({ message: 'User has been updated', options: { variant: 'success' } });
    } catch (e) {
      handleServerError(e);
    } finally {
      setIsLoadingFalse();
    }
  };

  const handleChangeMode = () => {
    if (selectedMode === options.add || selectedMode === options.edit) {
      return setSelectedMode(options.save);
    }

    if (selectedMode === options.save) {
      return updateData();
    }
  };

  const updateData = () => {
    const data = methods.getValues();

    updateEmployee(data);
  };

  useEffect(() => {
    if (isShow) {
      return setSelectedMode(options.edit);
    }
    return setSelectedMode(options.add);
  }, [isShow]);
  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography color="textSecondary" variant="h6">
          Additional Information
        </Typography>
        <Button
          startIcon={selectedMode?.icon}
          variant="contained"
          onClick={handleChangeMode}
          color="primary"
          size="small">
          <ButtonTitleWithLoading
            title={selectedMode?.text ?? ''}
            loaderVariant="paper"
            loading={isLoading}
          />
        </Button>
      </Box>

      {isShow && selectedMode?.type !== 'save' && (
        <BoxWrapper gridGap={16} isSimpleContainer>
          <DataRowItem {...our_reference_number} />
          <DataRowItem {...employment_type} />
        </BoxWrapper>
      )}
      {selectedMode?.type === 'save' && (
        <FormProvider {...methods}>
          <Box marginTop={1} display="flex" flexDirection="column" gridGap={16}>
            <AppTextFieldWithLimit label="Our reference number" name="our_reference_number" limit={32} />

            <Box width="100%" height="100%" display="flex" alignItems="center">
              <Controller
                name="employment_type"
                control={methods.control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <AppCheckbox
                        onChange={e => {
                          const checked = e.target.checked;
                          field.onChange(checked);
                        }}
                        color="primary"
                        checked={field.value}
                      />
                    }
                    label="Is an agency worker?"
                  />
                )}
              />
            </Box>
          </Box>
        </FormProvider>
      )}
    </Box>
  );
};
