import { IconType } from 'react-icons';
import React, { KeyboardEventHandler } from 'react';
import { Layout, Layouts } from 'react-grid-layout';
import { SnackbarOrigin, VariantType } from 'notistack';
import { Mark } from '@material-ui/core';
import { RegisterOptions } from 'react-hook-form';

import { CreateUserData } from './api/types';
import { DOCUMENT_STATUSES } from './constants';
import * as GeoJSON from 'geojson';
import { LatLngLiteral } from 'leaflet';
import { TimeLogsPeriods } from './views/time/TimeSettings/config';

export interface Tag {
  created_at?: string;
  has_trades?: boolean;
  order?: number;
  is_apprentice?: boolean;
  verified?: boolean;
  ISO3?: string;
  group?: string;
  uuid: string;
  name: string;
  label?: string;
  updated_at?: string;
}

export interface SurveyDTO {
  uuid: string;
  name: string;
  reversed: boolean;
}

export interface AuthFormValues {
  email: string;
  first_name: string;
  last_name: string;
  password_1: string;
  password_2: string;
  phone?: string;
  rememberMe?: boolean;
}

export interface FieldProps {
  limit?: number;
  lengthToShowLimitMessage?: number;
  rows?: number;
  name?: string;
  autoFocus?: boolean;
  multiline?: boolean;
  margin?: any;
  label?: string;
  rules?: any;
  multiple?: boolean;
  disabled?: boolean;
  isMobile?: boolean;
  token?: string;
  required?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  maxDate?: number;
  type?: string;
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
}

export interface Devices {
  isMobile: boolean;
  isDesktop: boolean;
  isLargeDesktop: boolean;
  isTablet?: boolean;
  iOS: boolean;
}

export interface ColorTheme {
  colorTheme: ColorThemeType;
}

export interface Nationality {
  uuid: string;
  name: string;
  iso3: string;
  order: number;
}

export interface Photo {
  uuid?: string;
  title?: string;
  file_name?: string;
  url?: string;
}

export interface Address {
  uuid?: string;
  // house number
  address_line_1: string;
  // street
  address_line_2?: string;
  // town
  address_line_3?: string;
  // postcode
  address_line_4?: string;
  address_snippet?: string;
  country?: string;
  lon?: number;
  lat?: number;
}

export interface Worker {
  uuid: string;
  biography: string;
  search_status: string;
  tags: Tag[];
  languages: Language[];
  address: Address;
  positions: Position[];
  qualifications: any;
}

export type UserPermission =
  | INTERFACE_PERMISSIONS
  | COMPANY_PERMISSIONS
  | TIME_INTERFACE_PERMISSIONS
  | TEAMS_PERMISSIONS;

export interface CompanyPosition {
  company_id: string;
  company: Company;
  role: string;
  owner: boolean;
  naive_check: boolean;
  permissions: UserPermission[];
}

export interface User {
  uuid: string;
  gender?: string | null;
  first_name: string;
  last_name: string;
  email?: string;
  phone?: string;
  date_of_birth?: string;
  positions?: CompanyPosition[];
  nationality?: Nationality;
  photo?: Photo;
  address?: Address;
  worker?: Worker;
  employee?: Employee;
  context?: {
    ni_number: string;
    eusr_id?: string;
    cscs_id?: string;
    cscs_ref_number?: string;
    share_code?: string;
    rtw_share_code?: string;
    rtw_verified?: boolean;
    cscs_verified?: boolean;
    cpcs_verified?: boolean;
    eusr_verified?: boolean;
  };
  is_superuser?: boolean;
  is_staff?: boolean;
  email_verified?: boolean;
  phone_verified?: boolean;
  qualifications?: any;
  id_verification?: IDVerification;
  rtw_verification?: RTWVerification;
  dashboards?: UserDashboards;
  roles?: RolesDTO;
  ni_number?: string;
}

export interface RolesDTO {
  user_id: string;
  roles_by_companies: {
    company_name: string;
    roles: RoleItem[];
  }[];
}

export interface RoleItem {
  module: string;
  role: string;
}

export interface UserDashboard {
  widgets?: WidgetItem[];
  layouts?: Layouts;
}

export interface UserDashboards {
  general?: UserDashboard;
  connect?: UserDashboard;
  teams?: UserDashboard;
  pay?: UserDashboard;
  time?: UserDashboard;
}

export interface WidgetItem {
  name: string;
  system_name: string;
  title_color?: 'primary' | 'secondary' | 'textPrimary';
  module_name?: string;
  url?: string;
  type?: string;
  layout?: Layout;
  available?: boolean;
  is_active?: boolean;
}

export interface WidgetFilters {
  team_id?: string;
}

export interface WidgetNumber {
  id: string;
  label: string;
  value: number;
}

export interface QualificationsDTO {
  certificates: { count: number; items: Certificate[] };
  courses: { count: number; items: Course[] };
  tests: { count: number; items: Test[] };
}

export interface CustomQualificationRequestData {
  title?: string;
  crt_type?: 'industry' | 'academic';
  issuer?: string;
  start_date?: string;
  expiry_date?: string;
  source: 'custom';
  reference_number?: string;
}

export interface UserPreviewData {
  user: User;
  surveys?: UserSurvey[];
  qualifications?: QualificationsDTO;
}

export interface Language {
  uuid?: string;
  name: string;
  order?: number;
}

export interface NavItem {
  name: string;
  icon?: IconType;
  nestedItems?: NavItem[];
  isActive?: boolean;
  permissions?: string[];
  breadcrumb?: string;
  link: string;
  isBottom?: boolean;
  disabled?: boolean;
}

export interface AutocompleteProps {
  name: string;
  items?: any[];
  multiple?: boolean;
  label?: string;
  noOptionsText?: string;
  rules?: any;
  disableClearable?: boolean;
  defaultValue?: string;
  margin?: any;
  onChange?: Function;
  disabled?: boolean;
  token?: string;
  group?: string;
  creatable?: boolean;
  isTags?: boolean;
  saveInDatabase?: boolean;
  endAdornment?: React.ReactNode;
  manualMode?: boolean;
  setSubstring?: (arg0: string) => void;
}

export interface TabItem {
  label: string;
  link: string;
  component?: React.FC;
  permission?: UserPermission;
}

export interface Certificate {
  title: string;
  crt_type: string;
  issuer: string;
  start_date: string;
  expiry_date: string;
  serial_number: string;
  source: string;
  uuid: string;
  document: Document;
  endorsements?: Endorsement[];
  additional_data?: CustomAdditionalData;
}

export interface CustomAdditionalData {
  uuid: string;
  certificate_id: string;
  reference_number: string;
  documents: CustomAdditionalDocumentData[] | null;
  image_url: string;
  image_s3_key: string;
}

export interface CustomAdditionalDocumentData {
  uuid: string;
  doc_url: string;
  doc_s3_key: string;
}

export interface CustomCertificate extends Omit<Certificate, 'crt_type'> {
  crt_type: 'industry' | 'academic';
}

export interface Endorsement {
  title: string;
  start_date: string | null;
  expiry_date: string | null;
  document: Document | null;
}

export interface Document {
  uuid: string;
  title: string;
  doc_type: string;
  url: string;
}

export interface Task {
  uuid?: string;
  date_done?: string;
  progress: ProgressOptions;
  message?: string;
  status: string;
  task_name: string;
}

export enum Statuses {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  PENDING = 'PENDING',
  NONE = 'NONE',
}

export interface Stages {
  30: string;
  60: string;
  75: string;
  95: string;
  100: string;
}

export type ProgressOptions = 30 | 60 | 75 | 95 | 100;

export interface Test {
  title: string;
  result: string;
  issuer: string;
  tst_type: string;
  exam_date: string;
  source: string;
  uuid: string;
  verified?: boolean;
}

export interface CompanyForManagement {
  uuid?: string;
  name?: string;
  logo?: string;
  trading_name?: string;
  owner_id?: string;
  first_name?: string;
  last_name?: string;
  outsourced?: number;
  not_outsourced?: number;
}

export interface Company {
  uuid?: string;
  cmp_type?: string;
  description?: string | null;
  creation_date?: string;
  gov_url?: string | null;
  website?: string | null;
  pay_company?: boolean;
  reg_number?: string;
  sic?: string;
  trading_name?: string | null;
  name?: string;
  logo?: any;
  status?: string;
  type?: string;
  date_of_creation?: string;
  address?: Address;
  url?: string;
  mask_name?: string;
  country?: string;
  registration_date?: string;
  legal_form?: string;
  main_activity?: string;
  principal_activity?: string;
  contact_address?: string;
  trading_addresses?: [];
  email?: string | null;
  twitter?: string | null;
  instagram?: string | null;
  facebook?: string | null;
  linkedin?: string | null;
  phone?: string | null;
  turnover?: number | string | null;
  workforce?: number | string | null;
  cost_of_sales?: number;
  gross_profit?: number;
  dividends?: number;
  total_current_assets?: number;
  total_current_liabilities?: number;
  subscribed_company?: boolean;
  modules?: {
    payroll?: boolean;
  };
}

export interface Position {
  title: any;
  company: Company;
  start_date: string;
  uuid: string;
  end_date: string | null;
  currently_active: boolean;
}

export interface HowItWorksStep {
  label: string;
  subtitle: string;
  imgPath: any;
  link?: string;
}

export interface RequestWorkerData {
  address?: Address;
  biography?: string;
  tags?: Tag[];
  languages?: Language[];
  positions?: Position[];
  search_status?: string;
  travel_time?: number | null;
}

export interface RequestPositionDataData {
  role: string;
  company: Company;
}

export interface SelectOption {
  value: string;
  name: string;
}

export type FilterOption = {
  type: 'select' | 'slider' | 'date' | 'input' | 'checkbox' | 'radio' | 'autocomplete' | 'grouped_select';
  dataType?: string;
  multiple?: boolean;
  label: string;
  field: string;
  values?: any;
  defaultValue?: any;
  marks?: Mark[];
  step?: number;
  max?: number;
  min?: number;
  hintMessage?: string;
  rules?: RegisterOptions;
  groupOneOptions?: SelectOption[];
  groupTwoOptions?: SelectOption[];
};

export interface FilterSection {
  title: string;
  filters: FilterOption[];
  expanded?: boolean;
}

export interface Subscription {
  uuid: string;
  display_name: string;
  started: string;
  ended: string;
  current_period_start: string;
  current_period_end: string;
  active: boolean;
  created_at: string;
  updated_at: string;
}

export interface SelectedCompanyAppData {
  teams?: Team[];
  paySchedules?: PaySchedule[];
  locations?: TimeAndAttendanceLocation[];
  shifts?: Shift[];
  isJobPosted?: boolean;
  companyDataLoading?: boolean;
  subscription?: Subscription;
}

export interface HelperState {
  userDataLoading?: boolean;
  notification?: AppNotification;
  redirectTo?: string;
  navItems: NavItem[];
  access_token: string;
  showTour?: boolean;
  selectedPosition?: CompanyPosition;
  selectedCompany?: Company;
  selectedCompanyAppData?: SelectedCompanyAppData;
  mainDashboardFilters?: LocationAndTeamsFilterState;
}

export interface LegendHelperState {
  userDataLoading?: boolean;
  notification?: AppNotification;
  redirectTo?: string;
  navItems: NavItem[];
  access_token: string;
  companies: Company[];
  selectedCompany?: Company;
  mainDashboardFilters?: LocationAndTeamsFilterState;
}

export interface AppNotification {
  message: string;
  description?: string;
  text?: string;
  options: {
    action?: {
      buttonText: string;
      type: 'verify_email' | null;
    };
    anchorOrigin?: SnackbarOrigin;
    permanent?: boolean;
    variant: VariantType;
  };
}

export interface Course {
  scheme: string;
  title: string;
  grade: string;
  expiry_date: string;
  exam_date: string;
  issuer: string;
  source: string;
  uuid: string;
  verified?: boolean;
}

export interface Job {
  contact_email: string;
  contact_name: string;
  contact_phone: string;
  description: string;
  duration: number;
  duration_type: string;
  job_type: string;
  name: string;
  number_of_workers: number;
  overtime_from: number;
  overtime_to: number;
  overtime_type: string;
  qualifications_required: string[];
  salary_from: number;
  salary_to: number;
  salary_type: string;
  start_date: string;
  status: string;
  tags: Tag[];
  urgently: boolean;
  uuid: string;
  job_id?: string;
  years_of_experience: number;
  computed: {
    connection: Connection;
    distance: {
      value: number;
      type: string;
    };
  };
  address: Address;
  company?: Company;
  updated_at?: string;
  created_at?: string;
}

interface CandidateHealthAndSafety {
  status: boolean;
  total: number;
  answered: number;
}

export interface Candidate {
  user_id: string;
  worker_id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  photo: Photo;
  gender: string;
  date_of_birth: string;
  nationality: Nationality;
  qualifications?: any;
  address: Address;
  biography: string;
  tags: Tag[];
  languages: Language[];
  positions: any[];
  search_status: string;
  id_verified: boolean;
  rtw_verified: boolean;
  cscs_verified: boolean;
  cpcs_verified: boolean;
  eusr_verified: boolean;
  health_and_safety: CandidateHealthAndSafety;
  computed: {
    connection: Connection;
    distance: {
      value: number;
      type: string;
    };
  };
}

export interface Connection {
  event: string;
  message: string;
  status: string;
  uuid: string;
}

export interface CheckerOptionField {
  name: string;
  display_name: string;
  field_type: string;
  description: string;
  url_info: string;
  value_ref: string;
  options?: string[];
}

export interface CheckerOption {
  name: string;
  fields: CheckerOptionField[];
}

export interface Checker {
  uuid: string;
  name: string;
  group: string;
  display_name: string;
  options: CheckerOption[];
}

export interface Image {
  file: File | null;
  url: string | undefined;
  name?: string;
}

export interface Passport {
  uuid: string;
  passport_type: string;
  additional_doc_type?: string;
  country: string;
  number: string;
  date_of_birth: string;
  expiration_date: string;
  nationality: string;
  gender: string;
  first_name: string;
  last_name: string;
  verified: boolean;
  document: Document;
  selfie: Document;
}

export interface Breadcrumb {
  name: string;
  route?: string;
  level: number;
}

export interface MapMarker {
  lat?: number;
  lon?: number;
  size?: number;
  picture?: string;
  draggable?: boolean;
  eventHandlers?: any;
  name: string;
  id: string;
}

export enum CONNECT_EVENTS {
  applied_by_worker = 'applied_by_worker',
  applied_by_employer = 'applied_by_employer',
  declined_by_employer = 'declined_by_employer',
  declined_by_worker = 'declined_by_worker',
  connected_by_employer = 'connected_by_employer',
  connected_by_worker = 'connected_by_worker',
}

export interface PersonalForm {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  date_of_birth: string | Date | null;
  nationality: Nationality;
  address: Address;
  languages: Language[];
  ni_number: string;
  cscs_ref_number: string;
  utrNumber: string;
  share_code: string;
  gender: { name: string; value: string };
}

export interface WorkerProfessionalForm {
  biography: string;
  roles: Tag[];
  skills: Tag[];
  search_status: Tag;
}

export interface ShortSurvey {
  name: string;
  uuid: string;
}

export interface Question {
  uuid?: string;
  text: string;
}

export interface Answer {
  uuid?: string;
  yes_no?: boolean | null;
  comment?: string | undefined | null;
}

export interface UserSurvey {
  name: string;
  uuid: string;
  required?: boolean;
  content: SurveyContent[];
}

export interface SurveyContent {
  question: Question;
  answer: Answer;
}

export interface LegendSurvey {
  name: string;
  uuid: string;
  questions: Question[];
}

export interface BACSFormat {
  system_name: string;
  name: string;
  description: string;
}

export interface HMRCSetting {
  name: string;
  tax_office_number: string;
  tax_office_ref: string;
  accounting_office_ref: string;
  utr: string;
  sender: string;
  sender_id: string;
  contact_first_name: string;
  contact_last_name: string;
  contact_email: string;
  contact_telephone: string;
  contact_fax: string;
  default: boolean;
  app_settings: boolean;
  deleted: boolean;
  company_id?: number;
  password: string;
}

export interface RadioDataItem {
  value: string | boolean;
  label: string;
}

export interface CISSubcontractor {
  effective_date: string;
  trading_name: string;
  unique_tax_reference: string;
  business_type: string;
  vat_registered: boolean;
  vat_registration_number: string;
  payment_method: string;
  pay_frequency: string;
  first_name: string;
  last_name: string;
  ni_number: string;
  telephone: string;
  region: string;
  address: string;
  bank_account: {
    account_name: string;
    account_number: string;
    sort_code: string;
  };
  company_name?: string;
  company_registration_number?: string;
}

export interface PaySchedule {
  custom_contract?: any;
  uuid?: string;
  name: string;
  company?: any;
  pay_frequency?: string;
  pay_weekday?: any;
  pay_last_workday?: boolean | null | string;
  created_at?: string;
  updated_at?: string;
  own_hmrc_settings?: boolean;
  email_pay_statement?: string | Date | null;
  sms_pay_statement?: string | Date | null;
  emailWeekday?: string;
  SMSWeekday?: string;
  outsourced?: boolean;
  payroll_fee_base?: number;
  payroll_fee_commission?: number;
  payroll_paid_by_worker?: boolean | string;
}

export type ColorOption =
  | 'grey'
  | 'secondary'
  | 'primary'
  | 'success'
  | 'warning'
  | 'error'
  | 'paper'
  | 'default';

export interface MappingItem {
  value: string;
  label: string;
  matchedValue?: string;
  required: boolean;
}

export interface TimesheetLine {
  adjustments: number;
  error: { message: string };
  expenses: number;
  basic_units: number;
  mile_rate: number;
  miles: number;
  overtime_units: number;
  overtime_rate: number;
  basic_rate: number;
  total: number;
  vat: number;
  worker_reference: string;
  employee: {
    first_name: string;
    last_name: string;
    ni_number: string;
    uuid: string;
    worker_reference: string;
  };
  basic: number;
  cis_deductions: number;
  employee_first_name: string;
  employee_id: string;
  employee_last_name: string;
  employee_ni_number: string;
  gross: number;
  mileage: number;
  net: number;
  overtime: number;
  payroll_fee: number;
  sent_to_hmrc: boolean;
  paid: boolean;
  uuid: string;
}

export interface EnrichedTimesheetLine extends TimesheetLine {
  total_pay: number;
  pay_scheme: string;
  tax_week_start: number;
  tax_week_end: number;
  tax_year_start: number;
  tax_year_end: number;
  payment_date: string;
  start_date: string;
  end_date: string;
  company: CompanyDTO;
}

export interface BankAccount {
  account_name: string;
  account_number: string;
  sort_code: string;
  building_society_roll_number?: string;
}

export interface Employee {
  job_title: string | Tag;
  email?: string;
  phone?: string;
  pay_schedule: any;
  first_name: string;
  last_name: string;
  gender?: string | null;
  nationality?: Nationality;
  date_of_birth?: string;
  region?: string;
  ni_number: string;
  cscs_id?: string;
  effective_date: string;
  trading_name: string;
  utr: string;
  cis_taxation_status?: string;
  display_cis_taxation_status?: string;
  cis_verification_date?: string;
  cis_verification_number?: string;
  business_type: string;
  vat_reg_number: string;
  vat_registered?: boolean;
  payment_method: string;
  bank_account?: BankAccount | null;
  address?: Address;
  employee_id: number;
  worker_reference: string;
  scheme_type: string;
  company_name?: string;
  company_reg_number?: string;
  company_utr?: string;
  uuid: string;
  user_id: string;
  pay_scheme: string;
  id_verified: boolean;
  rtw_verified: boolean;
  cis_verified: boolean;
  emergency_contact_name: string;
  emergency_contact_number: string;
  company?: CompanyDTO;
  is_agency_worker?: boolean;
  agency_ref_number?: string;
}

export interface InvitedUser {
  email: string;
  phone: string;
  company_id: string;
  user_id: string;
  status: string;
  payload: { [key: string]: unknown[] };
}

export interface WorkerOnboardingForm {
  address: Address;
  workerAddress: string;
  jobs: Tag[];
  ni_number: string;
  nationality: Nationality;
  date_of_birth: any;
  qualifications: Tag[];
  languages: Language[];
  specific_skills: Tag[];
  positions: Position[];
  search_status: string;
  travel_time: string;
}

export enum TaskNames {
  CHECK_EUSR = 'check_eusr',
  CHECK_QUALIFICATIONS = 'check_qualifications',
  CHECK_RTW = 'check_rtw',
  CHECK_PASSPORT = 'check_passport',
}

export enum TagGroups {
  ROLE = 'role',
  QUALIFICATION = 'qualification',
  SKILL = 'skill',
}

export interface Timesheet {
  pay_run_id: string;
  name: string;
  week: number;
  schedule: string;
  pay_schedule_id: string;
  pay_schedule: PaySchedule;
  company?: CompanyDTO;
  status: string;
  modified: string;
  created: string;
  workers: number;
  gross: number;
  end_date: string;
  payment_date: string;
  start_date: string;
  tax_week_end: number;
  tax_week_start: number;
  tax_year_end: number;
  tax_year_start: number;
  submitted_by: {
    first_name: string;
    last_name: string;
    uuid: string;
  };
}

export interface Payrun {
  pay_run_id: string;
  name: string;
  week: number;
  payment_date: string;
  schedule: string;
  status: string;
  workers: number;
  gross: number;
  gross_unpaid: number;
  net: number;
  net_unpaid: number;
  sms_notified: boolean;
  email_notified: boolean;
}

export interface PayrunLine {
  uuid: string;
  first_name: string;
  last_name: string;
  ni_number: string;
  worker_reference: string;
  pay_scheme: string;
  basic: number;
  basic_unpaid: number;
  overtime: number;
  overtime_unpaid: number;
  mileage: number;
  mileage_unpaid: number;
  cis_deductions: number;
  cis_deductions_unpaid: number;
  vat: number;
  vat_unpaid: number;
  net: number;
  net_unpaid: number;
  gross: number;
  gross_unpaid: number;
  employee_id: string;
  pay_run_id: string;
  paid: boolean;
  partly_paid: boolean;
  sms_notified: boolean;
  partly_sms_notified: boolean;
  email_notified: boolean;
  partly_email_notified: boolean;
  verification: {
    status: boolean;
    errors: any[];
    meta: any;
  };
}

export interface TimesheetTotals {
  gross: number;
  net?: number;
  payroll_fee?: number;
  workers: number;
}

export interface PayrunTotals {
  gross: number;
  net: number;
  payroll_fee: number;
  workers: number;
  gross_unpaid: number;
  net_unpaid: number;
  total_net: number;
  total_gross: number;
}

export interface Invite {
  email?: string;
  phone?: string;
  pay_scheme?: string;
}

export interface CISReport {
  company?: any;
  uuid: string;
  tax_year: number;
  tax_month: number;
  status: string;
  hmrc_transaction: any;
  hmrc_settings_id: string;
}

export interface CIS300Submit {
  user: {
    uuid: string;
    first_name: string;
    last_name: string;
  };
  company: {
    company_id: string;
    name: string;
    trading_name: string;
  };
  sent_by: string;
  hmrc_settings_id: string;
  hmrc_transaction_id: string;
  tax_year: number;
  tax_month: number;
  ir_mark_send: string;
  ir_mark_received: string;
  sent_by_staff: boolean;
  status: string;
  updated_at: string;
  created_at: string;
}

export interface PeriodData {
  end_date: string;
  start_date: string;
  tax_week_end: number;
  tax_year_end: number;
}

export enum INTERFACE_PERMISSIONS {
  PAYROLL_OUTSOURCED = 'interface:can_access_payroll_outsourced',
  PAYROLL = 'interface:can_access_payroll',
  STAFF_MANAGEMENT = 'interface:can_access_management',
  CONNECT = 'interface:can_access_connect',
  TEAMS = 'interface:can_access_teams',
  BILLING = 'interface:can_access_billing',
  TIME = 'interface:can_access_time',
}

export enum TIME_INTERFACE_PERMISSIONS {
  LOCATIONS_AND_SHIFTS = 'time:can_read_sites',
  USERS = 'time:can_update_workers',
}

export enum COMPANY_PERMISSIONS {
  CAN_EDIT = 'companies:can_edit_company',
}

export enum TEAMS_PERMISSIONS {
  CAN_CREATE_QUALIFICATION = 'teams:can_add_qualifications',
}

export interface OrderByItem {
  field_name: string | number;
  desc: boolean;
}

export interface TimeOrderByItem {
  field: string;
  desc: boolean;
}

export interface PayrollPayload {
  offset: number;
  limit?: number;
  pay_schedule_name?: string;
  tax_week_start_lte?: number;
  tax_week_start_gte?: number;
  tax_week_end_lte?: number;
  tax_week_end_gte?: number;
  tax_year_start_lte?: number;
  tax_year_start_gte?: number;
  tax_year_end_lte?: number;
  tax_year_end_gte?: number;
  pay_run_status?: string;
  pay_status?: string;
  outsourced?: boolean;
  order_by?: OrderByItem[];
  company_id?: string;
}

export interface PayrunPayload {
  offset?: number;
  limit?: number;
  substring?: string;
  pay_scheme?: string;
  tax_status?: string;
  id_status?: string;
  id_verified?: boolean;
  rtw_status?: string;
  cis_verified?: boolean;
  order_by?: OrderByItem[];
}

export interface ReportPayload {
  tax_month?: number;
  tax_year?: number;
  offset: number;
  limit?: number;
  order_by?: OrderByItem[];
  company_id: string;
}

export interface Staff {
  user_id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  owner: boolean;
  modules: any;
  company?: CompanyDTO;
}

export interface Role {
  uuid: string;
  name: string;
  description: string;
  module: Module;
  permissions: Permission[];
}

export interface Module {
  uuid: string;
  name: string;
  system_name: string;
  description: string;
}

export interface Permission {
  uuid: string;
  system_name: string;
  name: string;
  actions_group: string;
  description: string;
  visible: boolean;
}

export enum MODULES {
  PAYROLL = 'Payroll',
  COMPANY = 'Company',
  CONNECT = 'Connect',
  TEAMS = 'Teams',
  TIME = 'Time&Attendance',
}

export enum INVITE_MODULES {
  TIME = 'time',
  PAY = 'pay',
  TEAMS = 'teams',
  GENERAL = 'general',
}

export interface Payslips {
  pay_run_line_id: string;
  tax_year_end: number;
  tax_week_end: number;
  pay_scheme: string;
  basic: number;
  overtime: number;
  adjustments: number;
  vat: number;
  additions: number;
  net: number;
  gross: number;
  total_pay: number;
  pay_date: string;
  company: Company;
}

export interface EnrichedPayslips extends Omit<Payslips, 'company'> {
  deductions: number;
  tax_status: string;
  total_tax: number;
  payroll_fee: number;
  company: CompanyDTO;
}

export interface CreateUser {
  data: CreateUserData;
  redirectUrl?: string;
  saveToStore?: boolean;
  recaptcha_token: string;
  handleServerError: (e: any) => void;
}

export interface VerifyPhoneData {
  code: number;
  redirectUrl?: string;
  skip?: boolean;
  handleServerError: (e: any) => void;
}

export interface LoginData {
  username: string;
  password: string;
  redirectUrl?: string;
  recaptcha_token: string;
  handleServerError: (e: any) => void;
  showNotification: (notification: AppNotification) => void;
}

export interface UpdateUser {
  data: Partial<User>;
  isNotify?: boolean;
  callback?: Function;
  showNotification: (notification: AppNotification) => void;
  handleServerError: (e: any) => void;
}
export interface UploadAvatarData {
  file: File;
  saveToProfile: boolean;
  handleServerError: (e: any) => void;
}
export interface UpdateWorkerData {
  data: RequestWorkerData;
  showNotification: (notification: AppNotification) => void;
  handleServerError: (e: any) => void;
}

export interface IDVerification {
  expiration_date: string;
  status: DOCUMENT_STATUSES;
  user_id: string;
  uuid: string;
  verified: boolean;
}

export interface RTWVerification {
  expiration_date: string | null;
  check_date: string | null;
  status: any;
  user_id: string;
  uuid: string;
  verified: boolean;
}

export interface RTWProps {
  uuid: string;
  full_name: string;
  check_date: string;
  ref_number: string;
  company_name: string;
  permanent: boolean;
  expired: boolean;
  user_id: string;
  document: Document;
  photo: {
    uuid: string;
    title: string;
    doc_type: string;
    url: string;
  };
}

export interface AdditionalDocument {
  title: string;
  doc_type: string;
  description: string;
  uuid: string;
  user_id: string;
  document: {
    uuid: string;
    title: string;
    doc_type: string;
    file_name: string;
    url: string;
  };
}

export interface CompanyDTO {
  company_id: string;
  name: string;
  trading_name: string;
}

export interface PayrollUser {
  uuid: string;
  user_id: string;
  first_name: string;
  last_name: string;
  ni_number: string;
  worker_reference: string;
  email: string;
  phone: string;
  id_verified: boolean;
  rtw_verified: boolean;
  vat_registered: boolean;
  id_status: string;
  pay_scheme: string;
  taxation_status: string;
  business_type: string;
  job_role: string;
  nationality: string;
  pay_schedule: {
    pay_schedule_id: string;
    name: string;
  };
  company: CompanyDTO;
}

export interface Team {
  name: string;
  description?: string;
  logo?: any;
  uuid: string;
  member_count: number;
}

export interface CISStatusData {
  status: string;
  request_doc: string;
  acknowledgement_doc: string;
  response_doc: string;
}

export interface TeamMember {
  user_id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
}

export interface EmployeePaySchedule {
  company: CompanyDTO;
  pay_schedule: {
    pay_schedule_id: string;
    name: string;
  };
  pay_scheme: string;
  start_date: string;
  uuid?: string;
}

export interface EmailTemplate {
  name: string;
  template_id: string;
  uuid?: string;
}

export interface SMSTemplate {
  name: string;
  text: string;
  uuid?: string;
  registered_only?: boolean;
  system?: boolean;
}

export interface Agreement {
  active: boolean;
  company: Company;
  pay_schedule: PaySchedule;
  pay_scheme: string;
}

export interface BackToData {
  text: string;
  back: Function;
}

export interface BillingAddress {
  city?: string;
  country?: string;
  line1?: string;
  line2?: string;
  postal_code?: string;
  state?: string;
}

export interface CardForm {
  name_one_card?: string;
  cvc: string;
  expiry: string;
  focus?: string;
  number: string;
  address?: BillingAddress;
}

interface PayrollModuleInviteItem {
  pay_scheme: string;
  email?: string;
  phone?: string;
  pay_schedule_id: string;
}

interface TeamsModuleInviteItem {
  team_id: string;
  email?: string;
  phone?: string;
}

interface TimeModuleInviteItem {
  site_id: string;
  shift_id: string;
  email?: string;
  phone?: string;
}

export interface UniversalInvite {
  pay: PayrollModuleInviteItem[];
  teams: TeamsModuleInviteItem[];
  time: TimeModuleInviteItem[];
}

export interface UserManagementItem {
  user_id: string;
  ni_number: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  avatar: string;
}

export interface PieChartDataItem {
  color?: string;
  percent?: string;
  id: any;
  label: any;
  value: string | number;
}

export interface BillingContactResponse {
  first_name: string;
  last_name: string;
  email?: string;
  phone?: string;
  uuid: string;
  company_id: string;
}

export type BillingContact = Omit<BillingContactResponse, 'uuid' | 'company_id'>;

export interface CardDetails {
  uuid?: string;
  name_on_card?: string;
  number: string;
  last4?: string;
  brand?: string;
  exp_month: number;
  exp_year: number;
  cvc: string;
  address?: BillingAddress;
  created_at?: string;
  updated_at?: string;
}

export interface EmailSubscriptionGroup {
  id: string;
  name: string;
  description: string;
}

export interface FeedbackItem {
  reason: string;
  comment: string;
  extra?: any;
}

export interface SubscriptionInvoice {
  uuid: string;
  status: string;
  amount: number;
  next_payment_attempt: string;
  period_start: string;
  period_end: string;
  paid: boolean;
  invoice_pdf: string;
}

export interface DetailItem {
  title?: string;
  value?: string;
  verified?: boolean;
}

export interface BillingDataItem {
  product_name: string;
  product_id: string;
  quantity: number;
  amount: number;
  payment_day: string;
  paid: boolean;
}

export interface BillingData {
  total: number;
  tax: number;
  period_start?: string;
  period_end?: string;
  items: BillingDataItem[];
}

export interface WeatherLocation {
  latitude: number;
  longitude: number;
  name: string;
}

export interface DailyForecastItem {
  temperatureMax: number;
  weekDay: string;
  time: string;
  weatherCode: number;
}

export interface DayWeather {
  weatherCode: number;
  currentTemperature: number;
  precipitationProbability: number;
  windSpeed: number;
  time: string;
}

export enum ThemeType {
  OS = 'os',
  DARK = 'dark',
  LIGHT = 'light',
}

export type ColorThemeType = 'light' | 'dark';

export interface PublicLink {
  url: string;
  pay_scheme: string;
}

export enum InviteLinkType {
  PRIVATE = 'private_link',
  PUBLIC = 'public_link',
}

export interface ActivityLogEvent {
  weekday: string;
  day: string;
  shift_id: string;
  periods: ActivityLogEventPeriod[];
}

export interface ActivityLogEventPeriod {
  basic_hours: number;
  basic_minutes: number;
  check_in: EventTimeStamp;
  check_out: EventTimeStamp;
  overtime_hours: number;
  overtime_minutes: number;
  site: {
    name: string;
    description: string;
    uuid: string;
  };
}

export interface EmployeeActivityLog {
  weekday: string;
  day: string;
  basic_hours: number;
  basic_minutes: number;
  overtime_hours: number;
  overtime_minutes: number;
  periods: ActivityLogEventPeriod[];
}
export interface EmployeeActivityLogEventPeriod extends ActivityLogEventPeriod {
  clocked_in_hours: number;
  clocked_in_minutes: number;
}

interface EventTimeStamp {
  timestamp: string;
  event_type: 'check_in' | 'check_out';
  face_id: string;
  icon_url: string;
  face_verified: boolean;
  within_area: boolean;
  manually_added: boolean;
}

export enum TrackingType {
  BREADCRUMBS = 'breadcrumbs',
  IN_OUT = 'in_and_out',
  NO_TRACKING = 'no_tracking',
}

export interface TrackingTypeOption {
  value: TrackingType;
  name: string;
}

export interface OnboardingLocation {
  name: string;
  address: Address;
  payScheduleId?: string;
  teamMembersCount: number;
  shift: Shift;
  tracking_type?: TrackingTypeOption;
  approver?: string;
  polygon: GeoJSON.Polygon | null;
}

export interface ManualClockProps {
  selectedWorker?: { name: string; id: string };
  withPhoto?: boolean;
  timestamp: Date | 'now' | null;
  action: 'in' | 'out';
  verifiedLocation?: boolean;
  location?: TimeAndAttendanceLocation;
  shift?: { name: string; id: string };
}

export interface TimeAndAttendanceLocation {
  name: string;
  address: Address;
  center?: LatLngLiteral;
  teamMembersCount?: number;
  shifts?: Shift[];
  uuid?: string;
  active?: boolean;
  tracking_type?: TrackingTypeOption;
  polygon?: GeoJSON.Polygon | null;
}

export interface Shift {
  name: string;
  uuid?: string;
  deleted_at?: string;
  regular: ShiftScheduleDetails;
  overtime: ShiftScheduleDetails;
  basic_amount?: number | null;
  basic_unit: string;
  overtime_amount?: number | null;
  overtime_unit: string;
  breaks?: Break[];
}

export interface ShiftScheduleDetails {
  startTime?: string | null;
  endTime?: string | null;
  schedule: ShiftSchedule;
}

export interface Break {
  name: string;
  minutes: number;
  paid: boolean;
}

export interface ShiftSchedule {
  monday: DailySchedule;
  tuesday: DailySchedule;
  wednesday: DailySchedule;
  thursday: DailySchedule;
  friday: DailySchedule;
  saturday: DailySchedule;
  sunday: DailySchedule;
}

export interface DailySchedule {
  start: string;
  end: string;
}

export interface SiteShift {
  name: string;
  uuid?: string;
  shift_id?: string;
  deleted_at?: string;
  basic_amount: number | null;
  basic_unit: string;
  overtime_amount: number | null;
  overtime_unit: string;
  schedule: SiteShiftSchedule;
  breaks?: Break[];
}

export interface SiteShiftSchedule {
  monday: SiteDaySchedule[];
  tuesday: SiteDaySchedule[];
  wednesday: SiteDaySchedule[];
  thursday: SiteDaySchedule[];
  friday: SiteDaySchedule[];
  saturday: SiteDaySchedule[];
  sunday: SiteDaySchedule[];
}

export interface SiteDaySchedule {
  hour_type: string;
  start: {
    hours: number;
    minutes: number;
  };
  end: {
    hours: number;
    minutes: number;
  };
}

export interface BasicSite {
  name: string;
  uuid?: string;
  description?: string;
  address_line_1?: string;
  address_line_2?: string;
  address_line_3?: string;
  address_line_4?: string;
  address_snippet?: string;
  deleted_at?: string;
  country?: string;
  lat?: number;
  lon?: number;
  area_related: boolean;
  // POLYGON((0 0, 0 1, 0 2, 0 3, 0 0))
  geo_polygon?: string | null;
  // POINT(-0.1430083 51.50740649999999)
  geo_point?: string | null;
  tracking_type?: TrackingType;
}

export interface CreateSite extends BasicSite {
  shift: SiteShift;
}

export interface Site extends BasicSite {
  shifts: SiteShift[];
}

export interface TimeMember {
  user_id: string;
  uuid?: string;
  shift_id?: string;
  shift_name?: string;
  site_id?: string;
  site_name?: string;
  first_name: string;
  last_name: string;
  email?: string;
  phone?: string;
  gender?: string;
  date_of_birth?: string;
  basic_amount?: number;
  basic_unit?: string;
  overtime_amount?: number;
  overtime_unit?: string;
}

export interface AddTimeWorkerData {
  user_id: string;
  basic_amount: number | null;
  basic_unit: string | null;
  overtime_amount: number | null;
  overtime_unit: string | null;
}

export interface ActivityLogEventTotals {
  basic_hours: number;
  basic_minutes: number;
  overtime_hours: number;
  overtime_minutes: number;
}

export enum TrackEventType {
  TRACKING = 'tracking',
  CLOCK_IN = 'check_in',
  CLOCK_OUT = 'check_out',
}

export enum HourType {
  BASIC = 'basic',
  OVERTIME = 'overtime',
  OUT_OF_BOUNDS = 'out_of_bounds',
}

export interface TrackEvent {
  user_id: string;
  site_id: string;
  shift_id: string;
  event_type: TrackEventType;
  hour_type: HourType;
  within_area: boolean;
  geo_point: string;
  interval: number;
  timestamp: string;
  device: string;
  platform: string;
  model: string;
  lat?: number;
  lon?: number;
  icon_url?: string;
  fact_id?: string;
  face_verified?: boolean;
}

export interface CompanyPhoto {
  title: string;
  description?: string;
  url?: string;
  uuid: string;
}

export type CaseStudy = any;

export type Testimonial = {
  uuid?: string;
  testimonial: string;
  public: boolean | null;
  company_id: string;
  author_id?: string;
  author_name: string;
  author_position: string;
  author_company: string;
  photo: {
    title: string;
    url: string;
    file_name: string;
    uuid: string;
  };
};

export enum PayTagGroup {
  LOCATION = 'location',
  MANAGER = 'manager',
}

export interface PayTag {
  name: string;
  group: PayTagGroup;
  uuid?: string;
  company_id?: string;
  pay_schedule_id?: string;
  verified: boolean;
  created_at?: string;
  updated_at?: string;
}

export interface Starters {
  location?: string;
  manager?: string;
}

export enum PayNotificationStatuses {
  NOT_SENT = 'not-sent',
  PARTIAL_SENT = 'partial-sent',
  ALL_SENT = 'all-sent',
}

export interface OnboardingStep {
  name: string;
  component: React.ReactNode;
  modules?: INVITE_MODULES[];
  active?: boolean;
}

export interface ActiveOnboardingStep {
  step: OnboardingStep;
  number: number;
}

export enum TimeLogStatus {
  NOT_APPROVED = 'not_approved',
  APPROVED = 'approved',
  PARTLY_APPROVED = 'partly_approved',
}

export interface TimeLogByPeriod {
  basic_hours: number;
  basic_minutes: number;
  overtime_hours: number;
  overtime_minutes: number;
  clocked_in_hours: number;
  clocked_in_minutes: number;
  period: DailySchedule;
  status: TimeLogStatus;
  total_hours: number;
  workers: number;
  clocked_in_hours_decimals: number;
  basic_hours_decimals: number;
  overtime_hours_decimals: number;
  total_hours_decimals: number;
}

export interface TimeLogBySite {
  basic_hours: number;
  basic_minutes: number;
  overtime_hours: number;
  overtime_minutes: number;
  clocked_in_hours: number;
  clocked_in_minutes: number;
  period: DailySchedule;
  status: TimeLogStatus;
  total_hours: number;
  workers: number;
  site_id: string;
  site_name: string;
  clocked_in_hours_decimals: number;
  basic_hours_decimals: number;
  overtime_hours_decimals: number;
  total_hours_decimals: number;
}

export interface WorkerDayLog {
  date: string;
  hours: number;
  minutes: number;
  decimals: number;
}

export interface TimeLogByWorker {
  user_id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  gender: string;
  basic: WorkerDayLog[];
  overtime: WorkerDayLog[];
  total_hours: number;
  total_minutes: number;
  total_clocked_in_hours: number;
  total_clocked_in_minutes: number;
  total_clocked_in_hours_decimals: number;
  total_hours_decimals: number;
  period: DailySchedule;
  status: TimeLogStatus;
  check_in_ok: boolean;
  check_out_ok: boolean;
  face_in_ok: boolean;
  face_out_ok: boolean;
  auto_clock_out: boolean;
  manual_clock_out: boolean;
  check_in_ok_agg: boolean[];
  check_out_ok_agg: boolean[];
  face_in_ok_agg: boolean[];
  face_out_ok_agg: boolean[];
  manual_clock_out_agg: boolean[];
  auto_clock_out_agg: boolean[];
  checked_in_since: string | null;
}

export interface TimeWorker {
  user_id: string;
  avatar: string | null;
  gender?: string | null;
  first_name: string;
  last_name: string;
  email?: string;
  phone?: string;
  date_of_birth?: string;
  shifts: TimeWorkerShift[];
}

export interface TimeWorkerShift {
  company_id?: string;
  shift: TimeWorkerShiftItem;
  site: {
    site_id: string;
    name: string;
  };
  current_rates: CurrentRates;
}

export interface CurrentRates {
  basic: TimeWorkerShiftRate;
  overtime: TimeWorkerShiftRate;
}

export interface TimeWorkerShiftItem {
  shift_id: string;
  name: string;
}

export interface TimeWorkerShiftRate {
  rate: number | null;
  amount?: number | null;
  unit: string;
}

export interface TimeLogByShiftDateItemLog {
  approved: boolean;
  basic_amendment_hours: number;
  basic_amendment_minutes: number;
  basic_amendment_hours_decimals: number;
  basic_hours: number;
  basic_minutes: number;
  basic_hours_decimals: number;
  checked_in: string;
  checked_out: null;
  company_id: string;
  overtime_amendment_hours: number;
  overtime_amendment_minutes: number;
  overtime_amendment_hours_decimals: number;
  overtime_hours: number;
  overtime_minutes: number;
  overtime_hours_decimals: number;
  clocked_in_hours: number;
  clocked_in_minutes: number;
  clocked_in_hours_decimals: number;
  pulled: boolean;
  shift_id: string;
  shift_name: string;
  site_id: string;
  site_name: string;
  check_in_ok: boolean;
  check_out_ok: boolean;
  face_in_ok: boolean;
  face_out_ok: boolean;
  manual_clock_out: boolean;
  auto_clock_out: boolean;
}

export interface TimeLogByShiftDateItem {
  date: string;
  weekday: string;
  time_logs: TimeLogByShiftDateItemLog[];
  period: DailySchedule;
}

export interface TimeLogByShift {
  user_id: string;
  week: number;
  year: number;
  date_items: TimeLogByShiftDateItem[];
  totals: TimeTotalInfoProps;
}

export interface AddMemberForm {
  employee_id: string;
  shift_id: string;
  basic_amount: number | null;
  basic_unit: string | null;
  overtime_amount: number | null;
  overtime_unit: string | null;
}

export enum PullTimelogsStrategy {
  APPEND = 'append',
  REPLACE = 'replace',
}

export interface CSCSParams {
  cscs_id?: string;
  ni_number?: string;
  last_name: string;
}

export enum TimeLogIssue {
  LOCATION = 'Clocked Out outside of work location',
  FACE = 'FaceMatch not verified',
  AUTOMATED = 'Automated Clocked Out',
  MANUAL = 'Manual Clock Out by a manager',
}

export interface TimeTotalInfoProps {
  basic_hours: number;
  basic_hours_decimals: number;
  basic_minutes: number;
  overtime_hours: number;
  overtime_hours_decimals: number;
  overtime_minutes: number;
  total_hours: number;
  total_hours_decimals: number;
  total_minutes: number;
  total_clocked_in_hours: number;
  total_clocked_in_hours_decimals: number;
  total_clocked_in_minutes: number;
  total_amendments_hours: number;
  total_amendments_minutes: number;
  total_amendments_decimals: number;
  total_workers: number;
}

export interface TimePeriodSettings {
  weekday_start?: string | null;
  period_type: TimeLogsPeriods;
  period_start_date?: string | null;
}

export interface WorkersWorkedTimeOverview {
  start_date: string;
  end_date: string;
  total_pay: number;
  total_basic_hours: number;
  total_overtime_hours: number;
  average_hours: number;
  total_workers: number;
}

export interface TopPerformingWorker {
  uuid: string;
  clocked_hours: number;
  full_name: string;
  gender?: string;
  avatar?: string;
  cnaViewWorker?: boolean;
}
export interface TopPerformingHours {
  overtime_hours: number;
  regular_hours: number;
  approved_hours: number;
  pending_hours: number;
}
export interface TopPerformingTeamMembersLatWeekDTO extends TopPerformingHours {
  top_performing_workers: TopPerformingWorker[];
}

export interface TimePeriod {
  approved: boolean;
  auto_check_out: boolean;
  basic: number;
  basic_hours: number;
  basic_rate: number;
  check_in_ok: boolean;
  check_out_ok: boolean;
  checked_in: string;
  checked_out: string;
  company_id: string;
  created_at: string;
  face_in_ok: boolean;
  face_out_ok: boolean;
  manual_check_in: boolean;
  manual_check_out: boolean;
  manually_added: boolean;
  overtime: number;
  overtime_hours: number;
  overtime_rate: number;
  shift_id: string;
  site_id: string;
  total: number;
  total_hours: number;
  unpaid_break: boolean;
  updated_at: string;
  user_id: string;
  uuid: string;
  workday: string;
}

export type AIChatRole = 'user' | 'system' | 'assistant' | 'tool';

export interface AIChatAttachment {
  file_id: string;
  tools: [{ type: string }];
}
export interface AIChatMessage {
  role: AIChatRole;
  content: string;
  attachments?: AIChatAttachment[];
  tool_calls?: AIChatToolCall[];
  tool_call_id?: string;
}

export interface AIChatToolCall {
  function: {
    name: string;
    arguments: string;
  };
  id: string;
  type: string;
}

export interface AIChatFile {
  size: number;
  name: string;
  fileId: string;
}

export interface AIChatIdea {
  icon: any;
  title: string;
  requests: string[];
}
export interface OnSiteTradesData {
  trades: OnSiteTradesDataItem[];
  clocked_in_workers: number;
}

export interface OnSiteTradesDataItem {
  count: number;
  trade: string;
  percentage: number;
}

export interface LocationUserDTO {
  user_id: string;
  full_name: string;
  photo_url: string;
  location: string;
  last_clock_event: string;
  status: string;
  gender: string;
  shift_id: string;
  site_id: string;
}

export enum AI_MODEL {
  GPT_4O = 'gpt-4o',
  GPT_4O_MINI = 'gpt-4o-mini',
  GPT_3_5_TURBO = 'gpt-3.5-turbo',
}

export interface AppCustomTableColumn {
  name: string;
  title: string;
  flex: number;
  renderCell?: (row: any) => React.ReactNode;
  width?: string;
  justifyContent?: string;
  hint?: string;
  valueGetter?: (row: any) => React.ReactNode;
}

export interface LocationAndTeamsOption {
  uuid: string;
  name: string;
  is_active: boolean;
}
export interface LocationAndTeamsFilterDTO {
  uuid: string;
  company_id: string;
  user_id: string;
  locations: LocationAndTeamsOption[];
  teams: LocationAndTeamsOption[];
}

export interface LocationAndTeamsFilterState {
  locations: LocationAndTeamsOption[];
  teams: LocationAndTeamsOption[];
}
