import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useBooleanState } from '../../../hooks';
import { DeviceContext } from '../../../contexts';
import { TagGroups } from '../../../types';
import { generateFileLinkAndOpen } from '../../../utils';
import { QualificationType, UserPreviewProps } from '../types';
import {
  getPreparedAdditionalInfo,
  getPreparedBankDetails,
  getPreparedContactDetails,
  getPreparedEmergencyContactDetails,
  getPreparedLimitedCompanyDetails,
  getPreparedPaymentsDetails,
  getPreparedPersonalDetails,
  getPreparedRightToWorkDetails,
  getSurveyStatus,
  getWorkerQualifications,
} from '../utils';
import { NotificationContext } from '../../../contexts/notificationContext';

export const useUserPreviewData = ({
  userPreviewData,
  timesheetsLink,
  paymentsLink,
  agreementsLink,
  passport,
  companyId,
  showHealthAndSafetyPDFRequest,
}: UserPreviewProps) => {
  const { isMobile } = useContext(DeviceContext);
  const [isDocumentsInfoDialogOpen, setDocumentsInfoDialogOpen, setDocumentsInfoDialogClose] =
    useBooleanState(false);
  const { handleServerError } = useContext(NotificationContext);
  const [loadingPDF, setLoadingPDF] = useState<boolean>(false);
  const [formattedQualifications, setFormattedQualifications] = useState<QualificationType[]>([]);
  const { user, qualifications, surveys } = userPreviewData;

  const preparedPersonalDetails = useMemo(() => getPreparedPersonalDetails(user), [user]);
  const preparedAdditionalInfo = useMemo(() => getPreparedAdditionalInfo(user), [user]);

  const preparedContactDetails = useMemo(
    () => getPreparedContactDetails({ email: user?.email ?? '', phone: user?.phone ?? '' }),
    [user]
  );

  const preparedRightToWorkDetails = useMemo(() => getPreparedRightToWorkDetails(user), [user]);
  const preparedBankDetails = useMemo(() => getPreparedBankDetails(user), [user]);
  const preparedEmergencyContactDetails = useMemo(() => getPreparedEmergencyContactDetails(user), [user]);
  const preparedLimitedCompanyDetails = useMemo(() => getPreparedLimitedCompanyDetails(user), [user]);
  const preparedPaymentsDetails = useMemo(
    () => getPreparedPaymentsDetails({ timesheetsLink, paymentsLink, agreementsLink }),
    [timesheetsLink, paymentsLink, agreementsLink]
  );

  const downloadPDF = useCallback(async () => {
    try {
      if (!showHealthAndSafetyPDFRequest) {
        return;
      }
      setLoadingPDF(true);
      const res = await showHealthAndSafetyPDFRequest({ userId: user.uuid, companyId });
      generateFileLinkAndOpen(res, 'contentType');
      setLoadingPDF(false);
    } catch (e) {
      setLoadingPDF(false);
      handleServerError(e);
    }
  }, []);

  const preparedSurveysDetails = useMemo(() => {
    const healthSurvey = surveys?.find(item => item.name.includes('Health'));
    const surveyStatus = getSurveyStatus(healthSurvey?.content);
    return {
      onClick: downloadPDF,
      isShow: !!surveys,
      hasPrivilege: true,
      loading: loadingPDF,
      ...surveyStatus,
    };
  }, [downloadPDF, loadingPDF, surveys]);

  const skills = useMemo(
    () => user?.worker?.tags?.filter(tag => tag.group === TagGroups.SKILL)?.map(tag => tag.name),
    [user?.worker?.tags]
  );

  const role = useMemo(() => {
    if (user.positions?.length) {
      const roles = user?.positions?.map(position => `${position.role},  ${position.company.name}`) || [];
      return roles.join(', ');
    }
    if (user.employee?.job_title) {
      return String(user.employee?.job_title);
    }
    if (user.worker?.tags) {
      const roles: string[] = user.worker.tags
        .filter(tag => tag.group === TagGroups.ROLE)
        .map(tag => tag.name);
      return roles?.join(', ');
    }
    return '';
  }, [user.employee?.job_title, user.positions, user?.worker?.tags]);

  useEffect(() => {
    qualifications && setFormattedQualifications(getWorkerQualifications(qualifications));
  }, [qualifications]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return {
    user,
    skills,
    role,
    isMobile,
    isDocumentsInfoDialogOpen,
    formattedQualifications,
    preparedPersonalDetails,
    preparedContactDetails,
    preparedRightToWorkDetails,
    preparedBankDetails,
    preparedEmergencyContactDetails,
    preparedLimitedCompanyDetails,
    preparedPaymentsDetails,
    preparedSurveysDetails,
    preparedAdditionalInfo,
    setDocumentsInfoDialogOpen,
    setDocumentsInfoDialogClose,
  };
};
