import React, { ChangeEvent, useContext, useEffect, useState, useMemo } from 'react';
import {
  Box,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  makeStyles,
  Theme,
  Typography,
  Divider,
  Button,
  useTheme,
} from '@material-ui/core';
import { MdTune } from 'react-icons/md';
import { LuRefreshCw } from 'react-icons/lu';
import { AppAccordion } from '@vyce/core/src/components/AppAccordion';

import { DeviceContext } from '../contexts/deviceContext';
import { LocationAndTeamsFilterState, LocationAndTeamsOption, WidgetItem } from '../types';
import { AppCheckbox } from './inputs';
import { monochrome } from '../theme/styles';
import { useBooleanState } from '../hooks';
import { MAIN_CONTAINER_ID } from '../constants';
import {
  getTeamsAndLocationsOptionsRequest,
  updateTeamsAndLocationsOptionsRequest,
  resetTeamsAndLocationsOptionsRequest,
} from '../api/dashboards';
import { ButtonTitleWithLoading } from './ButtonTitleWithLoading';

interface Props {
  widgetItems: WidgetItem[];
  companyId: string;
  timePermission: boolean;
  teamPermission: boolean;
  mainDashboardFiltersFromState: LocationAndTeamsFilterState | undefined;
  updateMainDashboardFilters: ({ locations, teams }: LocationAndTeamsFilterState) => void;
  onWidgetsChangeViaSettings: (widgets: WidgetItem[]) => void;
  resetDashboards: () => void;
}

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  [key: string]: string | undefined;
};

type CandidateToUpdate = {
  name: string;
  is_active: boolean;
  system_name: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
      color: theme.palette.type === 'dark' ? monochrome.light : monochrome.dark,
    },
    dialogContent: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      gridGap: '8px',
    },
    dialog: {
      '& .MuiDialog-paper': {
        width: '100%',
        maxWidth: '600px',
        [theme.breakpoints.down('xs')]: {
          maxWidth: '100%',
        },
      },
    },
  })
);

const WidgetCheckboxItem = ({
  name,
  is_active,
  system_name,
  onChange,
}: WidgetItem & { onChange: (event: ChangeEvent<HTMLInputElement>) => void }) => {
  const classes = useStyles();
  const inputProps: InputProps = { 'data-system-name': system_name };

  return (
    <Box key={system_name} marginTop="12px">
      <FormControlLabel
        classes={{ label: classes.label }}
        control={
          <AppCheckbox
            variant="rectangle"
            onChange={onChange}
            color="primary"
            checked={is_active}
            inputProps={inputProps}
          />
        }
        label={name}
      />
    </Box>
  );
};

const CheckboxItem = ({
  name,
  is_active,
  uuid,
  onChange,
}: LocationAndTeamsOption & { onChange: (event: ChangeEvent<HTMLInputElement>) => void }) => {
  const classes = useStyles();
  const inputProps: InputProps = { 'data-system-id': uuid };
  return (
    <Box key={uuid} marginTop="12px">
      <FormControlLabel
        classes={{ label: classes.label }}
        control={
          <AppCheckbox
            variant="rectangle"
            onChange={onChange}
            color="primary"
            checked={is_active}
            inputProps={inputProps}
          />
        }
        label={name}
      />
    </Box>
  );
};

export const CustomizeDashboard: React.FC<Props> = ({
  widgetItems,
  companyId,
  mainDashboardFiltersFromState,
  timePermission,
  teamPermission,
  updateMainDashboardFilters,
  onWidgetsChangeViaSettings,
  resetDashboards,
}) => {
  const { isMobile } = useContext(DeviceContext);
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpenDialog, setCloseDialog] = useBooleanState(false);
  const [loading, setLoadingTrue, setLoadingFalse] = useBooleanState(false);
  const [accordionName, setAccordionName] = useState<'windows' | 'locations' | 'teams' | null>(null);
  const [locationList, setLocationList] = useState<LocationAndTeamsOption[]>([]);
  const [teamList, setTeamList] = useState<LocationAndTeamsOption[]>([]);
  const [candidatesToUpdate, setCandidatesToUpdate] = useState<CandidateToUpdate[]>([]);
  const selectedLocationsCount = useMemo(
    () => locationList.filter(item => item.is_active).length,
    [locationList]
  );
  const selectedTeamsCount = useMemo(() => teamList.filter(item => item.is_active).length, [teamList]);
  const showRestoreButton = useMemo(
    () =>
      teamList.some(item => !item.is_active) ||
      locationList.some(item => !item.is_active) ||
      candidatesToUpdate.some(item => !item.is_active) ||
      widgetItems.some(item => !item.is_active),
    [teamList, locationList, candidatesToUpdate, widgetItems]
  );

  const onCandidateToUpdateChange = (event: ChangeEvent<HTMLInputElement>) => {
    const systemName = event.target.dataset.systemName ?? '';
    if (candidatesToUpdate?.length === 0 || !systemName) return;

    setCandidatesToUpdate(value => {
      return (
        value?.map(item => {
          return item.system_name === systemName ? { ...item, is_active: !item.is_active } : item;
        }) || []
      );
    });
  };

  const fetchTeamsAndLocationsOptions = async () => {
    if (mainDashboardFiltersFromState?.locations.length || mainDashboardFiltersFromState?.teams.length) {
      setLocationList(mainDashboardFiltersFromState.locations);
      setTeamList(mainDashboardFiltersFromState.teams);
      return;
    }
    try {
      const res = await getTeamsAndLocationsOptionsRequest(companyId);
      setLocationList(res.data.locations);
      setTeamList(res.data.teams);
      handleUpdateMainDashboardFilters({ locations: res.data.locations, teams: res.data.teams });
    } catch (e) {
      console.error(e);
    } finally {
      reorderLists();
    }
  };

  const handleUpdateMainDashboardFilters = ({
    locations,
    teams,
  }: {
    locations?: LocationAndTeamsOption[];
    teams?: LocationAndTeamsOption[];
  }) => {
    // update redux store
    updateMainDashboardFilters({
      locations: locations || locationList,
      teams: teams || teamList,
    });
  };

  const updateWidgetsSettings = async () => {
    const mappedCandidatesToUpdate = candidatesToUpdate.reduce((sum, curr) => {
      sum[curr.system_name] = curr;
      return sum;
    }, {} as Record<string, CandidateToUpdate>);
    const newWidgetsSettings = widgetItems.map(item => ({
      ...item,
      is_active: mappedCandidatesToUpdate[item.system_name]?.is_active ?? item.is_active,
    }));
    await onWidgetsChangeViaSettings(newWidgetsSettings);
  };

  const updateTeamsAndLocationsOptions = async () => {
    try {
      await updateTeamsAndLocationsOptionsRequest({
        companyId,
        payload: {
          locations: locationList,
          teams: teamList,
        },
      });
      reorderLists();
      handleUpdateMainDashboardFilters({});
    } catch (e) {
      console.error(e);
    }
  };

  const resetTeamsAndLocationsOptions = async () => {
    try {
      const res = await resetTeamsAndLocationsOptionsRequest(companyId);
      setLocationList(res.data.locations);
      setTeamList(res.data.teams);
      handleUpdateMainDashboardFilters({ locations: res.data.locations, teams: res.data.teams });
    } catch (e) {
      console.error(e);
    }
  };

  const handleResetClick = async () => {
    resetTeamsAndLocationsOptions();
    resetDashboards();
  };

  const handleLocationChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLocationList(list =>
      list.map(item => {
        return item.uuid === event.target.dataset.systemId ? { ...item, is_active: !item.is_active } : item;
      })
    );
  };
  const handleTeamChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTeamList(list =>
      list.map(item => {
        return item.uuid === event.target.dataset.systemId ? { ...item, is_active: !item.is_active } : item;
      })
    );
  };
  const reorderLists = () => {
    setLocationList(list => {
      return list.sort((a, b) => (a.is_active === b.is_active ? 0 : a.is_active ? -1 : 1));
    });

    setTeamList(list => {
      return list.sort((a, b) => (a.is_active === b.is_active ? 0 : a.is_active ? -1 : 1));
    });
  };

  const handleUpdateClick = async () => {
    setLoadingTrue();
    await Promise.all([updateTeamsAndLocationsOptions(), updateWidgetsSettings()])
      .then(() => {
        setCloseDialog();
      })
      .finally(() => {
        setLoadingFalse();
      });
  };

  const handleClose = () => {
    setCloseDialog();
    setAccordionName(null);
  };

  const handleAccordionChange = (panel: 'windows' | 'locations' | 'teams') => {
    setAccordionName(panel === accordionName ? null : panel);
  };

  useEffect(() => {
    fetchTeamsAndLocationsOptions();
  }, [companyId]);

  useEffect(() => {
    setCandidatesToUpdate(
      widgetItems.map(item => ({
        name: item.name,
        is_active: item.is_active ?? false,
        system_name: item.system_name,
      }))
    );
  }, [widgetItems]);

  return (
    <>
      <Button
        onClick={setOpenDialog}
        size="small"
        style={{ backgroundColor: theme.palette.background.paper }}
        startIcon={<MdTune />}
        variant="outlined"
        color="primary">
        Customise your dashboard
      </Button>

      <Dialog
        fullScreen={isMobile}
        open={open}
        container={document.getElementById(MAIN_CONTAINER_ID)}
        onClose={handleClose}
        className={classes.dialog}
        aria-labelledby="responsive-dialog-title">
        <Box display="flex" alignItems="center" justifyContent="space-between" gridGap={8} height="60px">
          <DialogTitle id="responsive-dialog-title">Customise your dashboard!</DialogTitle>
          {showRestoreButton ? (
            <Box padding="32px 32px 0">
              <Button
                onClick={handleResetClick}
                size="small"
                endIcon={<LuRefreshCw />}
                variant="outlined"
                color="primary">
                Restore to default
              </Button>
            </Box>
          ) : null}
        </Box>
        <DialogContent className={classes.dialogContent}>
          <AppAccordion
            title="Which windows should be displayed?"
            noPadding
            outsideControl
            outsideExpanded={accordionName === 'windows'}
            panel="windows"
            onOutsideChange={handleAccordionChange}
            renderDetailsBlock={() => (
              <Box marginLeft="-5px" overflow="auto" maxHeight="calc(100vh - 315px)" height="max-content">
                {candidatesToUpdate.map(item => (
                  <WidgetCheckboxItem
                    key={item.system_name}
                    {...item}
                    onChange={onCandidateToUpdateChange}
                  />
                ))}
              </Box>
            )}
          />
          {timePermission && (
            <>
              <Divider />
              <AppAccordion
                title="Which Locations should be included?"
                noPadding
                outsideControl
                outsideExpanded={accordionName === 'locations'}
                panel="locations"
                onOutsideChange={handleAccordionChange}
                extraSummaryText={
                  selectedLocationsCount === locationList.length
                    ? ''
                    : `${selectedLocationsCount}/${locationList.length} selected`
                }
                renderDetailsBlock={() => (
                  <>
                    <Typography variant="caption">
                      This filter will be applied to all relevant widgets.
                    </Typography>
                    <Box
                      marginLeft="-5px"
                      overflow="auto"
                      maxHeight="calc(100vh - 470px)"
                      height="max-content">
                      {locationList.map(item => (
                        <CheckboxItem key={item.uuid} {...item} onChange={handleLocationChange} />
                      ))}
                    </Box>
                  </>
                )}
              />
            </>
          )}
          {teamPermission && (
            <>
              <Divider />
              <AppAccordion
                title="Which Teams should be included?"
                outsideControl
                outsideExpanded={accordionName === 'teams'}
                panel="teams"
                onOutsideChange={handleAccordionChange}
                extraSummaryText={
                  selectedTeamsCount === teamList.length
                    ? ''
                    : `${selectedTeamsCount}/${teamList.length} selected`
                }
                noPadding
                renderDetailsBlock={() => (
                  <>
                    <Typography variant="caption">
                      This filter will be applied to all relevant widgets.
                    </Typography>
                    <Box
                      marginLeft="-5px"
                      overflow="auto"
                      maxHeight="calc(100vh - 470px)"
                      height="max-content">
                      {teamList.map(item => (
                        <CheckboxItem key={item.uuid} {...item} onChange={handleTeamChange} />
                      ))}
                    </Box>
                  </>
                )}
              />
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button size="large" onClick={handleClose} variant="outlined">
            Close
          </Button>
          <Button
            size="large"
            color="primary"
            onClick={handleUpdateClick}
            variant="contained"
            disabled={!showRestoreButton}>
            <ButtonTitleWithLoading title="Update" loaderVariant="primary" loading={loading} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
