import axios from 'axios';
import { AskAIAssistantRequest, AskAIRequest } from '../types';
import { AIChatMessage } from '../../types';

export const askAIRequest = ({ messages, model, assistantId }: AskAIRequest) => {
  return axios.post('https://w6pzfq5p9j.execute-api.eu-west-1.amazonaws.com/prod', {
    messages,
    model,
    assistantId,
    withTools: true,
  });
};

export const speechToTextRequest = (audioFile: string) => {
  return axios.post('https://m2z4glo26g.execute-api.eu-west-1.amazonaws.com/prod', {
    file: audioFile,
  });
};

export const createThreadFileRequest = (file: string, fileName: string) => {
  return axios.post('https://5qcn34rp34.execute-api.eu-west-1.amazonaws.com/prod', {
    file,
    fileName,
  });
};

export const createThreadRequest = (messages: AIChatMessage[]) => {
  return axios.post('https://pp248v8k7a.execute-api.eu-west-1.amazonaws.com/prod', {
    messages,
  });
};

export const deleteThreadRequest = (threadId: string) => {
  return axios.delete(`https://94qvuxg6s6.execute-api.eu-west-1.amazonaws.com/prod/`, {
    data: {
      threadId,
    },
  });
};

export const askAiAssistantRequest = ({ message, assistantId, threadId }: AskAIAssistantRequest) => {
  return axios.post('https://24yz6k9jk6.execute-api.eu-west-1.amazonaws.com/prod', {
    message,
    assistantId,
    threadId,
  });
};
