import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { monochrome } from '../../theme/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      padding: theme.spacing(2),
      width: 350,
      overflowX: 'hidden',
      [theme.breakpoints.down('xs')]: {
        width: '100vw',
      },
    },
    filterButton: {
      width: 94,
      height: 40,
      borderRadius: '8px',
      color: theme.palette.type === 'dark' ? monochrome.mediumlight : monochrome.dark,
      fontWeight: 600,
      backgroundColor: theme.palette.background.paper,
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
        boxShadow:
          '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
      },
    },
    closeIcon: {
      marginRight: theme.spacing(1),
    },
    filterTitle: {
      cursor: 'pointer',
    },
    filterCount: {
      width: '20px',
      height: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%',
      color: theme.palette.type === 'dark' ? 'black' : 'white',
    },

    controlLabel: {
      marginRight: 0,
      '& .MuiTypography-root': {
        marginLeft: 0,
      },
    },
    radioButton: {
      paddingRight: 0,
    },
  })
);
