import React, { useContext } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';

import { ButtonTitleWithLoading, ReCAPTCHAComponent } from '@vyce/core/src/components';

import { DeviceContext } from '../contexts';
import { loginRequest } from '../api/auth';
import { useBooleanState, useRecaptcha } from '../hooks';
import { PasswordField, EmailField } from './controlled-inputs';
import { MAIN_CONTAINER_ID } from '../constants';
import { NotificationContext } from '../contexts/notificationContext';

interface Props {
  open: boolean;
  handleClose: Function;
  handleCancel?: Function;
  handleNext: Function;
  email: string;
  message?: string;
}

interface Form {
  password: string;
  email: string;
}

export const VerifyYourIdentityDialog: React.FC<Props> = ({
  open,
  handleClose,
  handleNext,
  email,
  message = 'Please log in once again.',
  handleCancel,
}) => {
  const { isMobile } = useContext(DeviceContext);
  const { reCaptchaRef, getReCaptchaToken } = useRecaptcha();
  const [loading, setLoadingTrue, setLoadingFalse] = useBooleanState(false);
  const { handleServerError } = useContext(NotificationContext);

  const methods = useForm<Form>({
    defaultValues: {
      email,
      password: '',
    },
  });
  const { watch, reset } = methods;
  const password = watch('password');

  const onSubmit = async ({ password, email }: Form) => {
    try {
      setLoadingTrue();
      const recaptcha_token = await getReCaptchaToken();

      await loginRequest({
        username: email,
        password,
        client_secret: recaptcha_token,
      });
      handleClose();
      handleNext();
      reset();
      setLoadingFalse();
    } catch (e) {
      setLoadingFalse();
      handleServerError(e);
    }
  };

  const handleDialogClose = () => {
    handleClose();
    reset();
  };

  const handleCancelDialog = () => {
    if (handleCancel) {
      handleCancel();
      reset();
    } else {
      handleDialogClose();
    }
  };

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleDialogClose();
        }
      }}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle>Verify your identity.</DialogTitle>

          <DialogContent>
            <DialogContentText>{message}</DialogContentText>

            <Box width={isMobile ? 'auto' : '500px'} paddingBottom={2}>
              <EmailField
                name="email"
                margin="normal"
                disabled
                label="Email Address"
                rules={{ required: 'Email Address is required' }}
              />
              <PasswordField
                name="password"
                margin="normal"
                label="Password"
                rules={{ required: 'Password is required' }}
              />
            </Box>
          </DialogContent>

          <ReCAPTCHAComponent ref={reCaptchaRef} />

          <DialogActions>
            <Button size="large" style={{ width: 94 }} variant="outlined" onClick={handleCancelDialog}>
              Close
            </Button>

            <Button
              size="large"
              cy-test-id="confirm-button"
              style={{ width: 94 }}
              color="primary"
              disabled={!password || loading}
              type="submit"
              variant="contained">
              <ButtonTitleWithLoading title="Login" loaderVariant="primary" loading={loading} />
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
