import { createTheme } from '@material-ui/core';
import { getBackground, getColor, getTextColor, monochrome } from './styles';

const defaultTheme = createTheme();

const getTheme = (darkMode: boolean) => {
  const color = getColor(darkMode);
  const textColor = getTextColor(darkMode);
  const background = getBackground(darkMode);
  return createTheme({
    palette: {
      primary: {
        main: color.primary,
        light: color.primaryBG,
        dark: color.primaryDark,
      },
      secondary: {
        main: color.secondary,
      },
      background: {
        default: background.default,
        paper: background.paper,
      },
      error: {
        main: color.error,
      },
      divider: color.divider,
      success: {
        main: color.success,
        light: color.successLight,
      },
      warning: {
        main: color.warning,
        light: color.warningLight,
      },
      text: {
        primary: textColor.dark,
        secondary: textColor.light,
        disabled: textColor.disabled,
      },
      action: {
        disabled: color.disabled,
        hover: background.default,
      },
      type: darkMode ? 'dark' : 'light',
    },
    shape: {
      borderRadius: 16,
    },
    typography: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
      fontSize: 14,
      h1: {
        fontSize: '64px',
        lineHeight: '88px',
        fontWeight: 400,
        [defaultTheme.breakpoints.down('xs')]: {
          fontSize: '56px',
        },
      },
      h2: {
        fontSize: '56px',
        lineHeight: '72px',
        fontWeight: 400,
        [defaultTheme.breakpoints.down('xs')]: {
          fontSize: '48px',
        },
      },
      h3: {
        fontSize: '48px',
        lineHeight: '56px',
        fontWeight: 400,
        [defaultTheme.breakpoints.down('xs')]: {
          fontSize: '36px',
        },
      },
      h4: {
        fontSize: '36px',
        lineHeight: '48px',
        fontWeight: 400,
        [defaultTheme.breakpoints.down('xs')]: {
          fontSize: '24px',
        },
      },
      h5: {
        fontSize: '24px',
        lineHeight: '32px',
        fontWeight: 400,
        [defaultTheme.breakpoints.down('xs')]: {
          fontSize: '18px',
        },
      },
      h6: {
        fontSize: '18px',
        lineHeight: '32px',
        fontWeight: 600,
        [defaultTheme.breakpoints.down('xs')]: {
          fontSize: '15px',
        },
      },
      subtitle1: {
        fontSize: '15px',
        fontWeight: 400,
      },
      caption: {
        fontSize: '13px',
        fontWeight: 400,
      },
    },
    overrides: {
      MuiFormHelperText: {
        root: {
          position: 'absolute',
          bottom: '-20px',
        },
        contained: {
          marginLeft: '4px',
          marginRight: '4px',
        },
      },
      MuiMobileStepper: {
        progress: {
          width: '100%',
          margin: '0 16px',
        },
      },
      MuiFormLabel: {
        root: {
          [defaultTheme.breakpoints.down('xs')]: {
            fontSize: '14px',
          },
        },
      },
      MuiFormControl: {
        root: {
          '& label.Mui-focused': {
            left: 12,
            color: color.primary,
          },
          '& label.Mui-disabled': {
            opacity: 0.5,
          },
          '& label.Mui-error': {
            color: color.error,
          },
          '& div.Mui-error': {
            backgroundColor: background.paper,
            borderColor: color.error,
          },
          '& div.Mui-disabled': {
            backgroundColor: color.disabled,
            opacity: 0.5,
            border: 'none',
          },
        },
      },
      MuiButton: {
        root: {
          borderRadius: '12px',
          fontWeight: 600,
          padding: '9.5px 22px',
          '& span.MuiButton-label': {
            fontSize: '13px',
            lineHeight: '26px',
            whiteSpace: 'nowrap',
          },
        },
        contained: {
          boxShadow: '2px 4px 12px rgba(20, 20, 43, 0.04)',
          color: color.primary,
          backgroundColor: background.paper,
          '&:hover': {
            backgroundColor: background.paper,
          },
        },
        outlined: {
          padding: '9.5px 22px',
          borderWidth: '2px !important',
          borderColor: darkMode ? monochrome.dark : monochrome.mediumlight,
          color: monochrome.mediumdark,
        },
        outlinedPrimary: {
          borderColor: color.primary,
        },
        label: {
          textTransform: 'none',
          fontSize: '15px',
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: color.primary,
          },
        },
        containedSizeLarge: {
          padding: '14.5px 22px',
          color: color.containedButton,
          '& span.MuiButton-label': {
            fontSize: '15px',
            lineHeight: '27px',
          },
        },
        outlinedSizeLarge: {
          padding: '12.5px 22px',
          '& span.MuiButton-label': {
            fontSize: '15px',
            lineHeight: '27px',
          },
        },
        outlinedSizeSmall: {
          height: '40px',
          borderRadius: '8px',
          '& span.MuiButton-label': {
            fontSize: '13px',
            lineHeight: '24px',
          },
        },
        containedSizeSmall: {
          padding: '4px 16px',
          borderRadius: '8px',
          height: '40px',
        },
      },
      MuiCheckbox: {
        root: {
          padding: '5px',
        },
      },
      MuiRadio: {
        root: {
          padding: '5px',
        },
      },
      MuiFormControlLabel: {
        root: {
          marginLeft: '0',
          marginRight: '7px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          display: 'inline-block',
        },
        label: {
          marginLeft: '3px',
          fontSize: '15px',
          color: darkMode ? monochrome.light : monochrome.darker,
          whiteSpace: 'nowrap',
          fontWeight: 500,
        },
      },
      MuiInputBase: {
        inputAdornedStart: {
          paddingLeft: '12px !important',
        },
      },
      MuiInputLabel: {
        formControl: {
          left: 12,
          fontSize: '15px',
        },
        filled: {
          [defaultTheme.breakpoints.down('xs')]: {
            top: 2,
            fontSize: '13px',
          },
        },
      },
      MuiSelect: {
        icon: {
          top: 'calc(50% - 24px)',
          position: 'inherit',
          color: '#A0A3BD',
          minWidth: '25px',
          minHeight: '25px',
        },
        filled: {
          paddingRight: '40px !important',
        },
      },
      MuiBackdrop: {
        root: {
          background: 'rgba(20, 20, 43, 0.25)',
          // backdropFilter: 'blur(10px)',
        },
      },
      MuiChip: {
        root: {
          borderRadius: '8px',
          backgroundColor: '#BFBEFC',
          color: '#4700AB',
          fontSize: '13px',
        },
      },
      MuiLinearProgress: {
        barColorPrimary: {
          borderRadius: '6px',
        },
      },
      MuiFilledInput: {
        multiline: {
          padding: 0,
          maxHeight: '100% !important',
        },
      },
      MuiMenu: {
        paper: {
          border: `1px solid ${color.divider}`,
          boxShadow: '0px 12px 32px rgba(20, 20, 43, 0.04)',
          borderRadius: '8px',
        },
        list: {
          padding: 0,
        },
      },
      MuiMenuItem: {
        root: {
          fontSize: '13px',
          color: monochrome.mediumdark,
          borderBottom: `1px solid ${color.divider}`,
          paddingTop: '12px',
          paddingBottom: '12px',
          '&:last-child': {
            marginRight: '0px',
            borderRadius: '0 0 8px 8px',
            borderBottom: 'none',
          },
          '&:first-child': {
            marginRight: '0px',
            borderRadius: '8px 8px 0 0',
          },
        },
      },
      MuiDialog: {
        paper: {
          borderRadius: '16px',
        },
      },
      MuiDialogActions: {
        root: {
          padding: '24px 32px 24px',
        },
        spacing: {
          '&> :not(:first-child)': {
            marginLeft: '16px',
          },
        },
      },
      MuiPaper: {
        outlined: {
          border: `1px solid ${color.paperBG}`,
          boxShadow: '2px 4px 12px rgba(20, 20, 43, 0.04)',
        },
      },
      MuiDialogTitle: {
        root: {
          padding: '32px 32px 0',
          '& .MuiTypography-root': {
            fontSize: '20px',
          },
        },
      },
      MuiDialogContent: {
        root: {
          padding: '8px 32px 0',
          overflowY: 'unset',
        },
      },
      MuiCssBaseline: {
        '@global': {
          a: {
            color: color.primary,
          },
          html: {
            scrollbarColor: `${color.primary} ${background.paper}`,
            overflow: 'overlay',
            overflowX: 'hidden',
          },
          body: {
            '&::-webkit-scrollbar, div::-webkit-scrollbar, ul::-webkit-scrollbar': {
              width: '14px',
              height: '14px',
              backgroundColor: background.paper,
            },
            '&::-webkit-scrollbar-track, div::-webkit-scrollbar-track, ul::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
              padding: '2px',
            },
            '&::-webkit-scrollbar-thumb, div::-webkit-scrollbar-thumb, ul::-webkit-scrollbar-thumb': {
              borderRadius: '10px',
              border: `3px solid ${background.paper}`,
              minHeight: 24,
              maxWidth: 2,
              backgroundColor: color.primary,
            },
          },
        },
      },
      MuiPopover: {
        root: {
          // should be bigger that modal overlay
          // @ts-ignore
          zIndex: defaultTheme.zIndex.modal + 1,
        },
        paper: {
          boxShadow: '0px 12px 32px rgba(20, 20, 43, 0.04)',
          border: `1px solid ${color.divider}`,
          '& .MuiList-root': {
            padding: 0,
          },
        },
      },
      MuiAvatar: {
        colorDefault: {
          color: darkMode ? monochrome.dark : monochrome.mediumlight,
          backgroundColor: background.default,
        },
      },
      MuiAccordion: {
        root: {
          boxShadow: 'none',
          border: 'none',
          '&::before': {
            content: 'none !important',
          },
          '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '12px 0',
          },
          '& .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: '48px !important',
          },
        },
      },
      MuiAccordionSummary: {
        root: {
          padding: '0 !important',
        },
      },
      MuiAccordionDetails: {
        root: {
          flexDirection: 'column',
          padding: 0,
        },
      },
    },
  });
};

export default getTheme;
