import React, { useMemo } from 'react';

import { Box, Typography, Grid, Divider } from '@material-ui/core';
import clsx from 'clsx';

import { getFormattedDate } from '@vyce/core/src/utils/dates';
import { DetailItem } from '@vyce/core/src/types';

import useStyles from '../../styles';
import { getFullYears } from '../../utils';
import { BoxWrapper } from './BoxWrapper';

interface PersonalDetailsProps {
  date_of_birth: DetailItem;
  gender: DetailItem | null;
  nationality: DetailItem;
  ni_number?: DetailItem;
  utr?: DetailItem;
  address?: DetailItem;
  is_agency_worker: DetailItem;
  agency_ref_number: DetailItem;
  isShowInfo?: boolean;
  isShowAgencyInfo?: boolean;
  children?: React.ReactNode;
}

const DataRowItem = ({ title, value, extraInfo }: DetailItem & { extraInfo?: string }) => {
  const classes = useStyles();

  if (!value) return null;
  return (
    <Box className={classes.recordWrapper}>
      <Grid item xs={12} sm={6} lg={5}>
        <Typography className={classes.bold}>{title}:</Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={7}>
        <Box display="flex" gridGap={16}>
          <Typography color="textSecondary" className={classes.bigTextWrap}>
            {value}
          </Typography>
          {extraInfo && (
            <Typography className={clsx(classes.bold, classes.grayscaleBodyColor)}>{extraInfo}</Typography>
          )}
        </Box>
      </Grid>
    </Box>
  );
};

export const PersonalDetails = ({
  date_of_birth,
  gender,
  nationality,
  ni_number,
  utr,
  address,
  is_agency_worker,
  agency_ref_number,
  isShowInfo = true,
  isShowAgencyInfo = false,
  children,
}: PersonalDetailsProps) => {
  const years = useMemo(() => getFullYears(date_of_birth?.value ?? ''), [date_of_birth.value]);
  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Typography color="textSecondary" variant="h6">
        Personal Details
      </Typography>
      <BoxWrapper gridGap={16}>
        {isShowInfo && (
          <>
            <DataRowItem {...ni_number} />
            <DataRowItem {...utr} />
            <DataRowItem {...address} />
          </>
        )}
        <DataRowItem
          {...date_of_birth}
          value={getFormattedDate(date_of_birth.value)}
          extraInfo={`${years} years old`}
        />
        <DataRowItem {...nationality} />
        <DataRowItem {...gender} />

        {(agency_ref_number.value || is_agency_worker.value) && isShowAgencyInfo && (
          <>
            <DataRowItem {...agency_ref_number} />
            <DataRowItem {...is_agency_worker} />
          </>
        )}

        {children && (
          <Box width="100%">
            <Divider style={{ height: '2px' }} />
            <Box mt={1}>{children}</Box>
          </Box>
        )}
      </BoxWrapper>
    </Box>
  );
};
